import {FormBuilder, MuiDrawer} from "@VaMusaka/mui-kit";
import { useTheme} from "@mui/material";
import {formFields, initialValues, validationSchema} from "apps/care/pages/CareGivers/constants";
import {useSnackbar} from "context/Snackbar";
import React from 'react';
import {useMatch, useNavigate} from "react-router-dom";
import {FormContainer} from 'components/layouts/form'
import api from "apps/care/api";

const Create = ({postAddAction}) => {

    const match = useMatch('/care-givers/create')
    const createOpen = Boolean(match)
    const theme = useTheme()
    const navigate = useNavigate()

    const snackbar = useSnackbar()

    const handleSubmit = (data) => {

        api({entity: 'caregivers', action: 'create', data})
            .then(() => {
                snackbar.setData({
                    severity: 'info',
                    message: 'Care giver created successfully'
                })
                navigate('/care-givers')
                postAddAction()

            })
            .catch(() => {
                snackbar.setData({
                    severity: 'error',
                    message: 'Error creating care giver.'
                })
            })
            .finally(() => {
                snackbar.setOpen(true)
            })
    }

    return (
        <MuiDrawer
            title={'Create Care Giver'}
            open={createOpen}
            trigger={null}
            handleClose={() => navigate(-1)}
            radius={theme.spacing(2)}
            width={450}
            height={`85vh`}
        >
            <FormContainer isFullWidth>
                <FormBuilder
                    title={'New Care Giver'}
                    initialValues={initialValues}
                    formFields={formFields}
                    validationSchema={validationSchema}
                    submitButtonText={'Create Care Giver'}
                    handleSubmit={handleSubmit}
                />
            </FormContainer>
        </MuiDrawer>

    );
};

export default Create;
