import React from 'react';
import store from "apps/gateway/redux/store";
import { Provider as ReduxProvider } from 'react-redux'
import Navigation from "components/Navigation";
import {pages} from './constants'

const GatewayApp = () => {
    return (
        <ReduxProvider store={store}>
            <Navigation module={'gateway'} pages={pages}/>
        </ReduxProvider>
    );
}

export default GatewayApp;