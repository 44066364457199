const {
    REACT_APP_DOMAIN,
    REACT_APP_PORT,
    REACT_APP_API_DOMAIN,
    REACT_APP_API_PORT,
    REACT_APP_API_PROTOCOL,
} = process.env

export const pages = [
    {
        title: 'Dashboard',
        path: '/',
        icon: 'Monitor',
        description: null,
    },
    {
        title: 'Customers',
        path: '/customers',
        icon: 'Briefcase',
        description: 'Create, update and manage customers.',
    },
    {
        title: 'Quotes',
        path: '/quotes',
        icon: 'FilePlus',
        description: 'Create, update and manage customer quotations.',
    },
    {
        title: 'Jobs',
        path: '/jobs',
        icon: 'Tool',
        description: 'Create, update and manage jobs, services and repairs.',
    },
    {
        title: 'Invoices',
        path: '/invoices',
        icon: 'FileText',
        description: 'Create, update and manage customer invoices.',
    },
    {
        title: 'Suppliers',
        path: '/suppliers',
        icon: 'Square',
        description: 'Create, update and manage suppliers.',
    },
    {
        title: 'Expenses',
        path: '/expenses',
        icon: 'Package',
        description:
            'Keep a record of items purchased for business use or inventory. Create, update and manage expenses',
    },
    {
        title: 'Services',
        path: '/services',
        icon: 'Sliders',
        description:
            'The list of services provided by your garage. Create, update and manage services to be used in quotes and invoices.',
    },
    // {
    //     title: 'Products',
    //     path: '/products',
    //     icon: 'ProductionQuantityLimits',
    // },

    { linebreak: true },
    {
        title: 'Users',
        path: '/users',
        icon: 'Users',
    },
    {
        title: 'Settings',
        path: '/settings',
        icon: 'Settings',
    },
]

const constants = {
    REACT_APP_DOMAIN,
    REACT_APP_PORT,
    REACT_APP_API_DOMAIN,
    REACT_APP_API_PORT,
    REACT_APP_API_PROTOCOL,

    APP_PAGES: pages,
}
export default constants
