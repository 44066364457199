import caregivers from "apps/care/api/caregivers";
import careplans from "apps/care/api/careplans";
import patients from "apps/care/api/patients";
import routes from "apps/care/api/routes";
import schedule from "apps/care/api/schedule";
import users from 'apps/care/api/users'
import graph from "apps/care/api/graph"
import {api} from 'lib/utilities'

const entities = {
    patients,
    careplans,
    caregivers,
    routes,
    schedule,
    graph,
    users
}

export default api(entities)
