import TableActionButtons from "components/bits/TableActionButtons";


export const tableColumns = (actions) => {
    return [
        {
            field: 'firstName',
            headerName: 'First Name',
            width: 200,
        },
        {
            field: 'lastName',
            headerName: 'Last Name',
            width: 200,
        },{
            field: 'email',
            headerName: 'Email',
            width: 200,
        },{
            field: 'id',
            headerName: '',
            width: 120,
            renderCell: ({row}) => (
                <TableActionButtons title={'users'} actions={actions(row.id)} />
            )
        }
    ]
}