import React from 'react'
import { Box, IconButton, Paper, Tooltip, Typography, useTheme } from '@mui/material'
import {  MuiContentTitle, MuiIcon } from '@VaMusaka/mui-kit'
import NothingToShow from 'components/bits/NothingToShow'
import { useNavigate } from 'react-router-dom'
import NoteForm from './NoteForm'
import useStyles from 'theme/styles'
import ViewNote from './ViewNote'

const Notes = ({ caregiver, updateCareGiver}) => {
	const theme = useTheme()
	const styles = useStyles()
	const navigate = useNavigate()
	const notes = caregiver?.notes

	return (
		<Paper variant='elevation' sx={{p: 2}}>
			<NoteForm note={null} postSubmit={(data) => updateCareGiver(data)}/>

			<Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
				<MuiContentTitle   title={'Notes'}/>

				<Tooltip title={'Add Note'}>
					<IconButton onClick={() => {
						navigate(`/care-givers/view/${caregiver.id}/add-note`)
					}} size={'small'}>
						<MuiIcon sx={{fontSize: theme.spacing(2)}} name={'Add'}/>
					</IconButton>
				</Tooltip>
			</Box>


			{
				!notes || notes.length === 0?
					<Box sx={{height: 100}}>
						<NothingToShow />
					</Box> :
					<Box sx={{pt: 2}}>
						{notes.map((note) => (
							<Paper
								variant={'contained'}
								className={styles.subtleCard}
								key={note.id} sx={{p: 1, mt: 1}}>

								<NoteForm notes={notes} postSubmit={(data) => updateCareGiver(data)}/>
								<ViewNote notes={notes} />

								<Box sx={{
									display: 'flex',
									alignItems: 'canter',
									justifyContent: 'space-between',
								}}>
									<Typography
										onClick={() => {
											navigate(`/care-givers/view/${caregiver.id}/view-note/${note.id}`)
										}}
										className={styles.clickable}
										variant={'body1'}>{note.subject}</Typography>
									<Box>
										<IconButton onClick={() => {
											navigate(`/care-givers/view/${caregiver.id}/update-note/${note.id}`)
										}} size={'small'}>
											<MuiIcon sx={{fontSize: theme.spacing(2)}} name={'Edit'}/>
										</IconButton>
									</Box>
								</Box>
							</Paper>
						))}
					</Box>
			}
		</Paper>

	)
}

export default Notes