import React from 'react';
import {Grid, Box, Typography, Button } from "@mui/material";
import NotFoundIso from 'assets/images/isometric/404_concept.jpg'
import {MuiIcon} from "@VaMusaka/mui-kit";
import PropTypes from "prop-types";

const NotFound = ({
                      handleBack= () => {console.log('Back Button Pressed')},
                      backBtnTxt= 'Go Back'
}) => {
    return (
        <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
                <Box
                    sx={{
                        backgroundColor: `rgba(17, 79, 90, 0.9)`,
                        borderRadius: {sx: 0, sm: 6},
                        overflow: 'hidden',
                        height: { sm : '85vh', },
                        m: '5vh',
                        p: 1
                    }}

                >
                    <Box
                        sx={{
                            borderRadius: {sx: 0, sm: 5},
                            backgroundImage: `url(${NotFoundIso})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            height: { sm : '100%', },
                        }}
                    />
                </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Box
                    sx={{
                        p: 5,
                        height: { sm : '80vh', },
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        width: '50%'
                    }}
                >

                    <Typography
                        color='primary'
                        sx={{
                            fontSize: '12',
                            pb: 2
                        }} >
                        Error 404
                    </Typography>

                    <Typography
                        color='primary'
                        sx={{
                            fontWeight: 900,
                        }}
                        variant={'h1'} >Something's</Typography>
                    <Typography
                        color='primary'
                        sx={{
                            fontWeight: 900,
                            pb: 2
                        }}
                        variant={'h1'}>Missing</Typography>

                    <Typography color='primary' sx={{
                        pb: 2
                    }}>
                        You're seeing this page because the URL you entered does not exist.
                    </Typography>

                    <Button
                        variant={'contained'}
                        startIcon={<MuiIcon name={'ArrowBack'} /> }
                        onClick={handleBack}
                    >
                        {backBtnTxt}
                    </Button>
                </Box>
            </Grid>
        </Grid>

    );
}

NotFound.propTypes = {
    handleBack: PropTypes.func,
    backBtnTxt: PropTypes.string
}
export default NotFound;