import { Avatar, Box, Button, IconButton, Paper, Tooltip, Typography, useTheme } from '@mui/material'
import dayjs from "dayjs";
import React from 'react';
import { useNavigate } from 'react-router-dom'
import { Stack } from '@mui/system'
import { MuiIcon } from '@VaMusaka/mui-kit'

const DetailsRow = ({patient}) => {
    const navigate = useNavigate();
    const theme = useTheme()

    return (
        <Paper variant='elevation' sx={{p: 2}}>
            <Stack spacing={1}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <Avatar src={null} alt={'user profile'} sx={{mt: 2, height: 50, width: 50, bgcolor: theme.palette.primary.main}}>
                        <MuiIcon name={patient.gender === 'Female' ? 'Face4' : 'Face6'} />
                    </Avatar>

                    <Typography
                        sx={{
                            mt: 2
                        }}
                        variant='overline' color='primary'>{patient.firstName} {patient.lastName}</Typography>
                </Box>

                <Box
                    spacing={2}
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        m: 1
                    }}>
                        <MuiIcon
                            sx={{fontSize: theme.spacing(2), mr: 1}}
                            name={'Transgender'} />

                        <Typography variant='body1'>
                            {patient.gender}
                        </Typography>
                    </Box>

                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        m: 1
                    }}>
                        <MuiIcon
                            sx={{fontSize: theme.spacing(2), mr: 1}}
                            name={'Cake'} />

                        <Typography variant='body1'>
                            {dayjs(patient.dateOfBirth).fromNow(true, true)} old
                        </Typography>
                    </Box>


                    <Tooltip title={'Update Patient'}>
                        <IconButton
                            sx={{ml: 2}}
                            onClick={() => navigate(`/patients/update/${patient.id}`)}
                            size='small'
                        >
                            <MuiIcon
                                sx={{fontSize: theme.spacing(2)}}
                                name={'Edit'} />
                        </IconButton>
                    </Tooltip>
                </Box>


                <Paper

                    sx={{
                        ml: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: 'bold',
                            fontSize: 18,
                            color: 'muted'
                        }}
                        variant='h6' color='primary'>Next of Kin</Typography>

                    {patient.nextOfKin ? <>
                        <Typography variant='overline' color='primary'>{patient?.nextOfKin?.name || 'Unknown'}</Typography>
                        <Box sx={{mt: 1}}>
                            <Typography variant='body2' size='small' color='muted' >{patient?.nextOfKin?.phone}</Typography>
                        </Box>
                    </> : <Box sx={{mt: 1}}>
                            <Button
                                onClick={() => navigate(`/patients/update/${patient.id}`)}
                                size='small'
                            > Add </Button>
                        </Box>
                    }
                </Paper>

            </Stack>
        </Paper>

    );
}

export default DetailsRow;
