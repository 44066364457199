import {MuiContentTitle} from "@VaMusaka/mui-kit";
import {Box, Button, List} from "@mui/material";
import api from "apps/care/api";
import SubtleListItem from "components/bits/SubtleListItem";
import PropTypes from "prop-types";
import React, {useLayoutEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import NothingToShow from "components/bits/NothingToShow";


const RoutesList = ({handleSelect, showHeaderAction, patientId}) => {
    const [routes, setRoutes] = useState([]);
    const navigate = useNavigate()

    const fetchRoutes = () => {
        let args = patientId ?
            {entity: 'routes', action: 'routes', data: patientId} :
            {entity: 'routes', action: 'list'}

        api(args)
            .then(({data}) => {
                setRoutes(data.results)
            })
            .catch(() => {})
            .finally(() => {})
    }


    const handleClick = (route) => {
        if(handleSelect) {
          return  handleSelect(route)
        }
        return navigate(`/routes/view/${route.id}`)
    }


    useLayoutEffect(() => {
        fetchRoutes()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Box>
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}
        >
            <MuiContentTitle title={'Routes'}/>
            {
                showHeaderAction &&
                <Button
                    onClick={() => navigate('/routes')}
                >
                    View All
                </Button>
            }
        </Box>

        {
            (!routes || routes.length === 0) && (
                <Box sx={{
                    height: 150
                }}>
                    <NothingToShow />
                </Box>
            )
        }



        <List sx={{ width: '100%'}}>
            {
                routes
                .map(route =>
                        <SubtleListItem
                            key={route.id}
                            primaryText={route.name}
                            handleSelect={handleClick}
                        />
                )
            }
        </List>
    </Box>

};

RoutesList.propTypes = {
    handleSelect: PropTypes.func,
    showHeaderAction: PropTypes.bool,
    patientId: PropTypes.string
}


RoutesList.defaultParameters = {
    handleSelect: null,
    showHeaderAction: false,
    patientId: null
}
export default RoutesList;
