import React from 'react'
import ModalPage from 'components/layouts/ModalPage'
import { MuiContentTitle } from '@VaMusaka/mui-kit'
import { Box, Typography, useTheme } from '@mui/material'
import dayjs from 'dayjs'
import { useParams } from 'react-router-dom'
import DOMPurify from 'dompurify'

const ViewNote = ({ notes }) => {

	const path = '/care-givers/view/:id/view-note/:itemId'
	const {itemId} = useParams()
	const theme = useTheme()
	const note = notes.find((note) => note.id === itemId)

	if (!note) return ''

	console.log(note.note)

	return (
		<ModalPage path={path} title={note.subject}>
			<MuiContentTitle title={note.subject} />

			<Box sx={{pt: 2}}>
				<Typography variant={'body1'} sx={{fontSize: theme.spacing(1.5)}}>
					Created {dayjs(note.createAt).fromNow()}
				</Typography>
			</Box>

			<Box sx={{pt: 1}}>
				{ <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(`<p>${note.note}</p>`) }} /> }
			</Box>
		</ModalPage>
	)
}

export default ViewNote