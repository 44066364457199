import TableActionButtons from "components/bits/TableActionButtons";
import dayjs from "dayjs";
import * as Yup from "yup";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime)

export const initialValues = {
    firstName: '',
    lastName: '',
    dateOfBirth: dayjs().subtract(30, 'years'),
    gender:'',
    contactNumber:''

}

export const formFields = [
    {
        component: 'MuiTextField',
        name: 'firstName',
        label: 'First Name',
    },
    {
        component: 'MuiTextField',
        name: 'lastName',
        label: 'Last Name',
    },
    {
        component: 'MuiSelectField',
        options: [
            {
                label: 'Male',
                value: 'Male'
            },
            {
                label: 'Female',
                value: 'Female'
            },
            {
                label: 'Other',
                value: 'other'
            }],
        name: 'gender',
        label: 'Gender',
    },
    {
        component: 'MuiTextField',
        name: 'contactNumber',
        label: 'Phone',
    },
    {
        component: 'MuiDateField',
        name: 'dateOfBirth',
        label: 'Date of birth',

    }
]


export const validationSchema =  Yup.object().shape({
    firstName: Yup.string().min(3).required('First Name is required'),
    lastName: Yup.string().min(3).required('Last Name is required'),
    dateOfBirth: Yup.date(),
    gender: Yup.string(),
    contactNumber:Yup.string().min(10).max(15).required('Contact Number is required')
})

export const noteInitialValues = {
    subject: '',
    note: ''
}

export const noteFormFields = [
    {
        component: 'MuiTextField',
        name: 'subject',
        label: 'Title',
    },
    {
        component: 'HtmlField',
        name: 'note',
        label: 'Note',
    },
]

export const noteValidationSchema =  Yup.object().shape({
    subject: Yup.string().min(3).required('Note title required'),
    // note: Yup.string().min(3).required('The note is required'),

})


export const tableColumns = (actions) => {
    return [
        {
            field: 'firstName',
            headerName: 'First Name',
            width: 120,
        },{
            field: 'lastName',
            headerName: 'Last Name',
            width: 120,
        },
        {
            field: 'gender',
            headerName: 'Gender',
            width: 120,
        },

        {
            field: 'dateOfBirth',
            headerName: 'Age',
            valueGetter: ({row}) => `${dayjs(row.dateOfBirth).fromNow(true)}`,
            width: 120,
        },


        // {
        //     field: 'email',
        //     headerName: 'Email',
        //     width: 200,
        // },
        {
            field: 'created_at',
            headerName: 'Created Date',
            // width: 200,
            valueGetter: ({ row }) => `${dayjs(row.created_at).fromNow()}`,
        },
        {
            field: 'id',
            headerName: '',
            width: 120,
            renderCell: ({ row }) => (
                <TableActionButtons title={'care givers'} actions={actions(row.id)} />
            ),
        },
    ]
}
