import List from "apps/care/pages/Patients/List";
import View from "apps/care/pages/Patients/View";
import Container from "components/layouts/Container";
import React from 'react';
import {Route, Routes} from "react-router-dom";

const Patients = () => {
    return (
        <Container title={'Patients'}>
            <Routes>
                <Route key={'list-patients'} path={'/'} element={<List />}/>
                <Route key={'list-patients'} path={'/create'} element={<List />}/>
                <Route key={'list-patients'} path={`/view/:id`} element={<View />}/>
                <Route key={'list-patients'} path={`/update/:id`} element={<View />}/>
            </Routes>
        </Container>
    );
};

export default Patients;
