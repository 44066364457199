import React from 'react';
import {Box, Typography} from "@mui/material";

const InlineTwoToneText = ({primary, secondary}) => {
    return             <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',

        }}
    >
        <Typography variant={'body1'} color={'muted'}>{primary}: </Typography>
        <Typography variant={'body1'} color={'primary'} sx={{ml: 1}}>{secondary}</Typography>
    </Box>
}

export default InlineTwoToneText;