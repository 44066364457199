import axios from 'axios'
import {baseRestActions} from "lib/utilities";

const prefix = `api/svc/care/patients`

const patients = {
    ...baseRestActions(prefix),
    routes: async (id) => axios.get(`${prefix}/${id}/routes`),
    carePlans: async (id) => axios.get(`${prefix}/${id}/care-plans`),
}

export default patients
