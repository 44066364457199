import useStyles from "theme/styles";
import {useNavigate} from "react-router-dom";
import {Box, Button, Paper, Typography, useTheme} from "@mui/material";
import NothingToShow from "components/bits/NothingToShow";
import {Masonry} from "@mui/lab";
import dayjs from "dayjs";
import React, {useLayoutEffect, useState} from "react";
import api from "apps/gateway/api";
import Create from "../pages/Applications/Create";
import {MuiContentTitle} from "@VaMusaka/mui-kit";
import OpenTenantTab from "./OpenTenantTab";

const Tenants = () => {
    const [tenants, setTenants] = useState([]);
    const navigate = useNavigate();
    const theme = useTheme()

    const { rowSpaceBetween} = useStyles()

    const fatchTenants = () => {
        api({
            entity: 'tenants',
            action: 'list',
        })
            .then(({data}) => setTenants(data))
            .catch(({error}) => console.error('Error loading tenants', error))
    }

    useLayoutEffect(() => {
       fatchTenants()
    }, [])


    return (
        <Box>
            <Create />
            <Box sx={{pl: 2, pr: 2}}>
                <MuiContentTitle
                    title={'My Applications'}
                    action={{
                        icon: 'AddCircleOutline',
                        label: 'Register Application',
                        callback: () => navigate('/applications/create'),
                    }}
                />
            </Box>

                {
                    (tenants.length === 0) ? (
                        <Paper sx={{
                                mt: 1,
                                p: 2
                            }}>
                            <NothingToShow />
                        </Paper>
                        ) : tenants.map((tenant) => (
                        <Masonry columns={{
                            xs: 1, sm: 2, md: 3
                        }} spacing={1}>
                            <Paper
                                key={tenant._id}
                                sx={{
                                    mt: 1,
                                    p: 2
                                }}>
                                <Box>

                                    <Box className={rowSpaceBetween}>
                                        <Typography variant={'overline'} color={'primary'}>{tenant.name}</Typography>
                                    </Box>
                                    <Box className={rowSpaceBetween}>
                                        <Typography
                                            variant={'body1'}
                                            sx={{fontSize: theme.spacing(1.5)}}>
                                            Created
                                        </Typography>

                                        <Typography
                                            variant={'body1'}
                                            sx={{fontSize: theme.spacing(1.5)}}>
                                            {dayjs(tenant.created_at).fromNow()}
                                        </Typography>
                                    </Box>

                                    <Box className={rowSpaceBetween}>
                                        <Typography
                                            variant={'body1'}
                                            sx={{fontSize: theme.spacing(1.5)}}>
                                                Status
                                        </Typography>
                                        <Typography
                                            variant={'body1'}
                                            sx={{fontSize: theme.spacing(1.5)}}>
                                            {tenant.active ? 'Active' : 'In Active'}
                                        </Typography>
                                    </Box>

                                </Box>
                                <Box className={rowSpaceBetween} sx={{pt:2}}>
                                    <OpenTenantTab tenant={tenant} />

                                    <Button
                                        variant={'contained'}
                                        size='small'
                                        onClick={() => {navigate(`/applications/view/${tenant.id}`)}}
                                    >
                                        View Details
                                    </Button>
                                </Box>
                            </Paper>
                        </Masonry>
                    ))
                }
        </Box>
    )
}

export default Tenants
