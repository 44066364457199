import {useLayout} from "context/Layout";
import PropTypes from "prop-types";
import React, {useCallback, useLayoutEffect} from 'react';
import {Box} from "@mui/material";

const Container = ({title, children}) => {
    const [layoutContext, setLayoutContext] = useLayout();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const updateTitle = useCallback((title) => {
        setLayoutContext({...layoutContext, headerTitle: title})
    })

    useLayoutEffect(() => {
        updateTitle(title);
        return () => {
            updateTitle('')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return (
        <Box sx={{
            pl: 1,
            pr: 1
        }}>
            {children}
        </Box>
    );
};

Container.propTypes = {
    title: PropTypes.string
}

Container.defaultParameters = {
    title: ''
}

export default Container;
