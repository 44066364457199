import React, {useLayoutEffect, useState} from 'react';
import {FormBuilder} from "@VaMusaka/mui-kit";
import {signInFormFields, signInValidationSchema} from "./constants";
import AuthLayout from 'components/layouts/Auth'
import api from "apps/shared/api";
import {useNavigate, useParams} from "react-router-dom";
import {completeAuthentication} from "lib/utilities";
import {useDispatch} from "react-redux";

const entity = 'auth'
const AcceptInvite = ({module}) => {
    const [inviteTokenValid, setInviteTokenValid] = useState(false)
    const [loading, setLoading] = useState(true)
    const {token} = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    console.info(inviteTokenValid, loading)

    const validateInviteToken = () => {
        api({entity, action : 'validateInviteToken', data: {token}})
            .then(({data}) => {
                setInviteTokenValid(data?.isValid)
            })
            .catch(err => console.error(err))
            .finally(() => {
                setLoading(false)
            })
    }


    const handleSubmit = (data) => {
        setLoading(false)
        data.token = token

        api({entity, action: 'acceptInvite', data})
            .then(({data}) => {
                completeAuthentication(data.token, dispatch, navigate)

            })
            .catch(() => {

            })
            .finally(() => setLoading(false))

    }

    useLayoutEffect(() => {
        validateInviteToken()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <AuthLayout module={module}>
            <FormBuilder
                initialValues={{
                    email: '',
                    password: '',
                }}
                handleSubmit={handleSubmit}
                validationSchema={signInValidationSchema}
                formFields={signInFormFields}
                title="Sign in to accept invite"
                submitButtonText="Accept Invite"
                submitFullWidth
            />
        </AuthLayout>
    );
}

export default AcceptInvite;