
import {MuiContentTitle} from "@VaMusaka/mui-kit";
import NothingToShow from "components/bits/NothingToShow"
import {Box, Button, List, Paper} from "@mui/material";
import api from "apps/care/api";
import SubtleListItem from "components/bits/SubtleListItem";
import React, {useLayoutEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";

const CareGiversListCard = () => {
    const [careGivers, setCareGivers] = useState([]);
    const navigate = useNavigate()

    const fetchPatients = () => {

        api({entity: 'caregivers', action: 'list'})
            .then(({data}) => {
                setCareGivers(data.results)
            })
            .catch(() => {
              console.error('failed to get care givers')
            })
    }

    useLayoutEffect(() => {
        fetchPatients()
    }, []);



    return (
        <Paper sx={{pt: 1, pl: 2, pr: 2 }} elevation={3} >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <MuiContentTitle title={'Care Givers'} />
                <Button onClick={() => navigate('/care-givers')}>View All</Button>
            </Box>
            {
                (!careGivers || careGivers.length === 0) && (
                    <Box sx={{
                        height: 150
                    }}>
                        <NothingToShow />
                    </Box>
                )
            }
            <List sx={{width: '100%'}}>
                {
                    careGivers.map(patient =>
                        <SubtleListItem
                            avatar={{
                                src: patient.avatar,
                                alt: patient.firstName
                            }}
                            handleSelect={() => navigate(`/care-givers/view/${patient.id}`)}
                            key={patient.id}
                            primaryText={`${patient.firstName} ${patient.lastName}`}
                        />
                    )
                }
            </List>
        </Paper>
    );
}

export default CareGiversListCard;
