import {SimpleKpi} from "@VaMusaka/mui-kit";
import {Grid} from "@mui/material";
import api from "apps/care/api";
import React, {useEffect, useState} from 'react';

const KpiRow = () => {
    const [patients, setPatients] = useState('0.00')
    const [careGivers, setCareGivers] = useState('0.00')
    const [routes, setRoutes] = useState('0.00')
    const [draftCarePlans, setDraftCarePlans] = useState('0.00')

    const fetchCountMetric = (Model, callback, filters= {}) => {
        api({
            entity: 'graph',
            action: 'get',
            data: {
                type:'count',
                entity: Model,
                    ...filters
            }
        }).then(({data}) => {
            callback(data.results)
        }).catch(err => {
            console.error(err)
        })
    }

    useEffect(() => {
        fetchCountMetric('Patients', setPatients)
        fetchCountMetric('Routes', setRoutes)
        fetchCountMetric('CareGivers', setCareGivers)
        fetchCountMetric('CarePlans', setDraftCarePlans, {})
    }, [])


    return (
        <Grid container spacing={1} sx={{mb: 1}}>
            <Grid item xs={12} sm={6} md={3}>
                <SimpleKpi title={'Patients'} metric={patients} paperProps={{sx: {padding: 2}}}/>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
                <SimpleKpi title={'Care Givers'} metric={careGivers} paperProps={{sx: {padding: 2}}}/>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
                <SimpleKpi title={'Routes'} metric={routes} paperProps={{sx: {padding: 2}}}/>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
                <SimpleKpi title={'Care Plans'} metric={draftCarePlans} paperProps={{sx: {padding: 2}}}/>
            </Grid>
        </Grid>
    );
}

export default KpiRow;
