import React from 'react'
import {FormBuilder} from "@VaMusaka/mui-kit";
import {formFields, validationSchema} from "apps/care/pages/CareGivers/constants";
import {useSnackbar} from "context/Snackbar";
import { useNavigate, useParams } from 'react-router-dom'
import {FormContainer} from 'components/layouts/form'
import api from "apps/care/api";
import dayjs from 'dayjs'
import ModalPage from 'components/layouts/ModalPage'

const Update = ({careGiver, postUpdateAction = () => {}}) => {
    const path = '/care-givers/update/:id'
    const {id} = useParams()
    const snackbar = useSnackbar()
    const navigate = useNavigate()
    const handleSubmit = (data) => {
        delete data.user
        api({entity: 'caregivers', action: 'update',
        data: {id, ...data}})
            .then(() => {
                snackbar.setData({
                    severity: 'info',
                    message: 'Care giver updated successfully'
                })
                navigate(-1)
                postUpdateAction()

            })
            .catch((err) => {
                console.error(err)
                snackbar.setData({
                    severity: 'error',
                    message: 'Error updating care giver.'
                })
            })
            .finally(() => {
                snackbar.setOpen(true)
            })
    }

    return (
        <ModalPage
            title={'Create Care Giver'}
            path={path}
        >
            <FormContainer isFullWidth>
                <FormBuilder
                    title={'Update Care Giver'}
                    initialValues={{...careGiver, dateOfBirth: dayjs(careGiver.dateOfBirth)}}
                    formFields={formFields}
                    validationSchema={validationSchema}
                    submitButtonText={'Update Care Giver'}
                    handleSubmit={handleSubmit}
                />
            </FormContainer>
        </ModalPage>

    );
};

export default Update;
