import React from 'react';
import Container from "components/layouts/Container";
import { Route, Routes as DomRoutes } from 'react-router-dom'
import List from "apps/care/pages/Routes/List";
import View from 'apps/care/pages/Routes/View'


const Routes = () => {
    return (
        <Container title={'Routes'}>
            <DomRoutes>
                <Route key={'list-routes'} path={'/'} element={<List/>}/>
                <Route key={'view-routes'} path={'/view/:id'} element={<View />}/>

            </DomRoutes>
        </Container>
    );
};

export default Routes;
