import {MuiIcon} from "@VaMusaka/mui-kit";
import {Badge, Box, IconButton, Toolbar, Tooltip, Typography} from "@mui/material";
import {useLayout} from "context/Layout";
import React, {useEffect, useState} from 'react';
import {MuiAppBar} from './styles'
import Proptypes from 'prop-types';
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {signOut} from "lib/utilities";

const Header = ({open, toggleDrawer}) => {
    const [title, setTitle] = useState('')
    const [context] = useLayout();
    const navigate = useNavigate()
    const dispatch = useDispatch();

    useEffect(() => {
        if(context) {
            const headerTitle = context.headerTitle;
            setTitle(headerTitle)
        }
    }, [title, setTitle, context]);

    return (
        <MuiAppBar position="fixed" open={open}>
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={toggleDrawer}
                    edge="start"
                    sx={{
                        mr: 5,
                    }}
                >
                    {open ? <MuiIcon name='Segment' /> : <MuiIcon name='Menu' /> }
                </IconButton>

                <Typography variant="h6" noWrap component="div">
                    {title}
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                    <IconButton onClick={() => navigate('/notifications')} size="large" aria-label="show 4 new mails" color="inherit">
                        <Badge
                            // badgeContent={4}
                            color="error">
                            <MuiIcon name='Mail' />
                        </Badge>
                    </IconButton>
                    <Tooltip title={'Sign Out'}>
                        <IconButton
                            onClick={() =>
                                signOut(dispatch)
                        }
                            size="large"
                            aria-label="account of current user"
                            aria-controls="primary-search-account-menu"
                            aria-haspopup="true"
                            color="inherit"
                        >
                            <MuiIcon name='Logout' />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Toolbar>
        </MuiAppBar>
    );
};

Header.propTypes = {
    open: Proptypes.bool,
    toggleDrawer: Proptypes.func,
}

Header.defaultParameters = {
    open: true,
    toggleDrawer: () => {},
}

export default Header;
