import React from 'react';

import Gradient from 'rgt'
import {Box, Typography, useTheme} from "@mui/material";

const Jumbotron = () => {
    const theme = useTheme();
    return (
        <Box>
                <Box
                    sx={{
                        borderRadius: 1,
                        mt: 2,
                        p: 2,
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 250,
                        border: 'solid 2px #ccc',
                        backgroundSize: '100%',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center center',
                }}>
                    <Typography variant="h1" sx={{ fontWeight: 900 }}>
                        <Gradient dir="left-to-right" from="#132D51" to={theme.palette.primary.main}>
                            Next Generation
                        </Gradient>
                    </Typography>
                    <Box sx={{p: 1}}>
                        <Typography variant="overline" sx={{fontSize: '1.1em', fontWeight: 500}}>
                            <Gradient dir="left-to-right" from={theme.palette.primary.main} to="#007CF0">
                                The Ultimate Data-Driven Business Management Solution
                            </Gradient>
                        </Typography>
                    </Box>
                </Box>
        </Box>
    );
}
export default Jumbotron;