import CareGivers from "apps/care/pages/CareGivers";
import CarePlans from "apps/care/pages/CarePlans/CarePlans";
import Home from "apps/care/pages/Home";
import Patients from "apps/care/pages/Patients";
import Routes from "apps/care/pages/Routes";
import Scheduler from "apps/care/pages/Scheduler";
import NothingToShow from "components/bits/NothingToShow";
import Inbox from "apps/care/pages/Inbox";
import Users from "apps/care/pages/Users";


export const pages = [
    {
        key:'/',
        layout: 'dashboard',
        Component: Home,
        requireAuth: true,
        title: 'Home',
        path: '/',
        icon: 'Home',
        description: null,
    },
    {
        key: '/care-givers',
        layout: 'dashboard',
        Component: CareGivers,
        requireAuth: true,
        title: 'Care Givers',
        path: '/care-givers/*',
        icon: 'PeopleAlt',
        description: null,
    },
    {
        key: '/patients',
        layout: 'dashboard',
        Component: Patients,
        requireAuth: true,
        title: 'Patients',
        path: '/patients/*',
        icon: 'ElderlyWoman',
        description: null,
    },

    {
        key: '/care-plans',
        layout: 'dashboard',
        Component: CarePlans,
        requireAuth: true,
        title: 'Care Plans',
        path: '/care-plans/*',
        icon: 'Article',
        description: null,
    },    {
        key: '/routes',
        layout: 'dashboard',
        Component: Routes,
        requireAuth: true,
        title: 'Routes',
        path: '/routes/*',
        icon: 'Route',
        description: null,
    },
    {
        key: '/scheduler',
        layout: 'dashboard',
        Component: Scheduler,
        requireAuth: true,
        title: 'Scheduler',
        path: '/scheduler/*',
        icon: 'CalendarViewDay',
        description: null,
    },

    {
        key: '/inbox',
        layout: 'dashboard',
        Component: Inbox,
        requireAuth: true,
        title: 'Inbox',
        path: '/inbox/*',
        icon: 'AllInbox',
        description: null,
    },
    {
        key: '/users',
        layout: 'dashboard',
        Component: Users,
        requireAuth: true,
        title: 'Users',
        path: '/users/*',
        icon: 'PeopleOutline',
        description: null,
    },
    {
        key: '/settings',
        layout: 'dashboard',
        Component: NothingToShow,
        requireAuth: true,
        title: 'Settings',
        path: '/settings/*',
        icon: 'Tune',
        description: null,
    }
]
