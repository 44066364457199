import React, { useLayoutEffect, useState } from 'react'
import { Avatar, Box, IconButton, Paper, Tooltip, Typography, useTheme } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import api from 'apps/care/api'
import { Stack } from '@mui/system'
import { MuiContentTitle, MuiIcon } from '@VaMusaka/mui-kit'
import dayjs from 'dayjs'
import NothingToShow from 'components/bits/NothingToShow'
import Update from '../Update'
import Notes from './Notes'
import { Masonry } from '@mui/lab'

const View = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [caregiver, setCaregiver] = useState(null)

    const theme = useTheme();
    const params = useParams()
    const navigate = useNavigate()
    const {id} = params

    const fetchCaregiver = () => {
        setIsLoading(true)
        api({entity: 'caregivers', action: 'get', data: id})
            .then(({data}) => {
                setCaregiver(data)
            })
            .catch((err) => {
                console.error('failed to load caregiver', err)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    useLayoutEffect(() => {
        fetchCaregiver()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    if(isLoading) {
        return <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                height: '50vh',
            }}
        >
            <Typography variant={'h5'}>Loading...</Typography>
        </Box>
    }

    return (
          <Box>
              <Update postUpdateAction={fetchCaregiver}  careGiver={caregiver} />
              <Masonry columns={3} sx={{mt: 1}}>
                  <Paper variant='elevation' sx={{p: 2}}>
                      <Stack spacing={1}>
                          <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center',  justifyContent: 'center'}}>
                            <Avatar src={null} alt="caregiver name" sx={{height: 50, width: 50, bgcolor: theme.palette.primary.main}}>
                                <MuiIcon name={caregiver.gender === 'Female' ? 'Face4' : 'Face6'} />
                            </Avatar>
                          </Box>

                          <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center',  justifyContent: 'center'}}>
                              <Typography variant='overline' color='primary'>
                                  {caregiver.firstName} {caregiver.lastName}
                              </Typography>
                          </Box>


                          <Box
                              spacing={2}
                              sx={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  justifyContent: 'center'
                              }}
                          >
                              <Box sx={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  m: 1
                              }}>
                                  <MuiIcon
                                      sx={{fontSize: theme.spacing(2), mr: 1}}
                                      name={'Transgender'} />

                                  <Typography variant='body1'>
                                      {caregiver.gender}
                                  </Typography>
                              </Box>

                              <Box sx={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  m: 1
                              }}>
                                  <MuiIcon
                                      sx={{fontSize: theme.spacing(2), mr: 1}}
                                      name={'Cake'} />

                                  <Typography variant='body1'>
                                      {dayjs(caregiver.dateOfBirth).fromNow(true, true)} old
                                  </Typography>
                              </Box>


                              <Tooltip title={'Update Care Giver'}>
                                  <IconButton
                                      sx={{ml: 2}}
                                      onClick={() => navigate(`/care-givers/update/${caregiver.id}`)}
                                      size='small'
                                  >
                                      <MuiIcon
                                          sx={{fontSize: theme.spacing(2)}}
                                          name={'Edit'} />
                                  </IconButton>
                              </Tooltip>
                          </Box>
                      </Stack>
                  </Paper>


                  <Paper variant='elevation' sx={{p: 2}}>
                      <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                          <MuiContentTitle   title={'Routes / Rota'}/>
                          <Tooltip title={'Add Caregiver to Route'}>
                              <IconButton onClick={() => navigate('/scheduler')} size={'small'}>
                                  <MuiIcon sx={{fontSize: theme.spacing(2)}} name={'Add'}/>
                              </IconButton>
                          </Tooltip>
                      </Box>


                      <Box sx={{height: 100}}>
                          <NothingToShow />
                      </Box>
                  </Paper>


                    <Notes caregiver={caregiver} updateCareGiver={setCaregiver}/>
                  <Paper variant='elevation' sx={{p: 2}}>
                      <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                          <MuiContentTitle   title={'Timesheets'}/>
                      </Box>


                      <Box sx={{height: 100}}>
                          <NothingToShow />
                      </Box>
                  </Paper>
              </Masonry>
          </Box>
    );
};

export default View;
