import React, {useLayoutEffect, useState} from 'react';
import {Avatar, Box, Container, Divider, Grid, Paper, Typography} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import api from "apps/gateway/api";
import {MuiContentTitle} from "@VaMusaka/mui-kit";
import CareIcon from 'assets/images/icons/care.png'
import InlineTwoToneText from "components/bits/InlineTwoToneText";
import OpenTenantTab from "apps/gateway/shared/OpenTenantTab";
import dayjs from "dayjs";

const ViewApplication = () => {
    const [tenant, setTenant] = useState({})
    const [loading, setLoading] = useState(true);
    const params = useParams();
    const navigate = useNavigate()
    const {id} = params

    const fetchTenant = () => {
        api({entity: 'tenants', action: 'get', data: id})
            .then(({data}) => setTenant(data))
            .catch(({error}) => console.error('Error loading tenants', error))
            .finally(() => setLoading(false))
    }

    useLayoutEffect(() => {
        fetchTenant()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if(loading) return

    return (
        <Container>
            <Paper sx={{ p: 4 }}>
                <MuiContentTitle
                    title={'Application Details'}
                    action={{
                        icon: 'Edit',
                        label: 'Update Details',
                        callback: () => navigate(`/applications/update/${id}`),
                    }}
                    />

                <Divider sx={{mt: 1, mb: 1}}/>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}>
                            <Paper elevation={5} variant={'elevation'}>
                                <Avatar
                                    src={CareIcon}
                                    alt={'user profile'}
                                    sx={{
                                        p: 2,
                                        height: 100, width: 100
                                }}/>
                            </Paper>
                            <Box sx={{
                                ml: 2,
                                display: 'flex',
                                flexDirection: 'column',
                            }}>
                                <Typography
                                    sx={{
                                        fontWeight: 'bold',
                                        fontSize: 18,
                                        color: 'muted',
                                        textTransform: 'capitalize'
                                    }}
                                    variant='h6' color='primary'>
                                    {tenant.module} Maneja</Typography>
                                <Typography variant='overline' color='primary'>{tenant.name}</Typography>
                                <OpenTenantTab tenant={tenant} />
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box sx={{
                            ml: 2,
                            mt: 1,
                            display: 'flex',
                            flexDirection: 'column',
                        }}>
                            <InlineTwoToneText primary={'Subdomain'} secondary={tenant.domain} />
                            <InlineTwoToneText primary={'Created'} secondary={dayjs(tenant.created_at).fromNow()} />
                            <InlineTwoToneText primary={'Last Updated'} secondary={dayjs(tenant.updated_at).fromNow()} />
                            <InlineTwoToneText primary={'Status'} secondary={tenant.active ? 'active' : 'in-active'} />
                        </Box>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
}

export default ViewApplication;