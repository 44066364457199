
import {MuiContentTitle} from "@VaMusaka/mui-kit";
import {Box, Button, List, Paper} from "@mui/material";
import api from "apps/care/api";
import SubtleListItem from "components/bits/SubtleListItem";
import React, {useLayoutEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import NothingToShow from "../../../../../components/bits/NothingToShow";

const PatientListCard = () => {
    const [patients, setPatients] = useState([]);
    const navigate = useNavigate()

    const fetchPatients = () => {
        api({entity: 'patients', action: 'list'})
            .then(({data}) => {
                setPatients(data.results)
            })
            .catch(() => {
              console.error('failed to get patients')
            })
    }

    useLayoutEffect(() => {
        fetchPatients()
    }, []);



    return (
        <Paper sx={{pt: 1, pl: 2, pr: 2 }} elevation={3} >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >

                <MuiContentTitle title={'Patients'} />
                <Button
                    onClick={() => navigate('/patients')}
                >View All</Button>
            </Box>

            {
                (!patients || patients.length === 0) && (
                    <Box sx={{
                        height: 150
                    }}>
                        <NothingToShow />
                    </Box>
                )
            }

            <List sx={{width: '100%'}}>
                {

                    patients.map(patient =>
                        <SubtleListItem
                            avatar={{
                                src: patient.avatar,
                                alt: patient.firstName
                            }}
                            handleSelect={() => navigate(`/patients/view/${patient.id}`)}
                            key={patient.id}
                            primaryText={`${patient.firstName} ${patient.lastName}`}
                        />
                    )
                }
            </List>
        </Paper>
    );
}

export default PatientListCard;
