import {MuiContentTitle, MuiIcon} from "@VaMusaka/mui-kit";
import {Masonry} from "@mui/lab";
import { Box, IconButton, List, Paper, Tooltip, Typography, useTheme} from "@mui/material";
import api from "apps/care/api";
import DetailsRow from "apps/care/pages/Patients/View/Partials/DetailsRow";
import NothingToShow from "components/bits/NothingToShow";
import SubtleListItem from "components/bits/SubtleListItem";
import React, { useLayoutEffect} from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import Update from '../Update'


const ViewPatient = () => {
    const [patient, setPatient] = React.useState({});
    const [carePlans, setCarePlans] = React.useState([]);
    const [routes, setRoutes] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const theme = useTheme();
    const navigate = useNavigate()
    const params = useParams()
    const {id} = params

    const fetchCarePlans = () => {
        setIsLoading(true);
        api({entity: 'patients', action: 'carePlans', data:  id})
            .then(({data}) => {
                setCarePlans(data.results)
            })
            .catch((err) => {
                console.error('failed to get care plans', err)
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const fetchRoutes = () => {
        setIsLoading(true);
        api({entity: 'patients', action: 'routes', data:  id})
            .then(({data}) => {
                setRoutes(data.results)
            })
            .catch((err) => {
                console.error('failed to get patient routes', err)
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const fetchPatient = () => {
        setIsLoading(true);
        api({entity: 'patients', action: 'get', data: id})
            .then(({data}) => {
                setPatient(data)
            })
            .catch(() => {
                console.error('failed to get patient')
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    useLayoutEffect(() => {
        fetchPatient()
        fetchCarePlans()
        fetchRoutes()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    if(isLoading) {
        return <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                height: '50vh',
            }}
        >
            <Typography variant={'h5'}>Loading...</Typography>
        </Box>
    }

    return (
        <Box>
            {patient &&
                <Update patient={patient} postAddAction={fetchPatient} />
            }


            <Masonry columns={3} sx={{mt: 1}}>
                {patient && <DetailsRow patient={patient} /> }
                <Paper variant='elevation' sx={{p: 2}}>
                    <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <MuiContentTitle
                            title={'Medical Condition'}
                        />
                        <Tooltip title={'update'}>
                            <IconButton onClick={() => {}} size={'small'}>
                                <MuiIcon sx={{fontSize: theme.spacing(2)}} name={'Edit'}/>
                            </IconButton>
                        </Tooltip>
                    </Box>
yar
                    <SubtleListItem
                        primaryText={patient?.medicalCondition?.details || ' Nothing to show'} />
                </Paper>


                <Paper variant='elevation' sx={{p: 2}}>
                    <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <MuiContentTitle   title={'Care Plans'}/>
                        <Tooltip title={'Add Care Plan'}>
                            <IconButton onClick={() => {}} size={'small'}>
                                <MuiIcon sx={{fontSize: theme.spacing(2)}} name={'Add'}/>
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <List>
                        {
                            carePlans && carePlans.map((careplan) => {
                                return (
                                    <SubtleListItem
                                        key={careplan.id}
                                        primaryText={careplan?.name || `${careplan?.status} plan`}
                                        handleSelect={() => navigate(`/care-plans/view/${careplan.id}`)}
                                        icon={{name: 'Edit', handleIconClick: () => navigate(`/care-plan/edit/${careplan.id}`)}}
                                    />
                                )
                            })
                        }
                    </List>
                </Paper>


                <Paper variant='elevation' sx={{p: 2}}>
                    <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <MuiContentTitle
                            title={'Patient Routes'}/>

                        <Tooltip title={'Add Patient to Route'}>
                            <IconButton onClick={() => {navigate('/routes')}} size={'small'}>
                                <MuiIcon sx={{fontSize: theme.spacing(2)}} name={'Add'}/>
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <List sx={{ width: '100%'}}>
                        {(routes)
                            .map(route =>
                                <SubtleListItem  key={route.id} primaryText={route.name} handleSelect={() => navigate(`/routes/view/${route.id}`)}/>
                            )
                        }
                    </List>

                </Paper>

                <Paper variant='elevation' sx={{p: 2}}>
                    <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <MuiContentTitle
                            title={'Messages'}/>
                    </Box>

                    <Box sx={{height: 100}}>
                        <NothingToShow />
                    </Box>
                </Paper>
            </Masonry>
        </Box>
    );
}

export default ViewPatient;
