import TableActionButtons from "components/bits/TableActionButtons";
import dayjs from "dayjs";

export const tableColumns = (actions) => {
    return [
        {
            field: 'patient',
            headerName: 'Patient',
            width: 400,
            valueGetter: ({row}) => `${row.patient.firstName} ${row.patient.lastName}`,
        }, {
            field: 'status',
            headerName: 'Status',
            width: 120,
        },
        {
            field: 'updatedAt',
            headerName: 'Last Updated',
            width: 120,
            valueGetter: ({ row }) => `${dayjs(row.updated_at).fromNow(true)}`,
        },
        {
            field: 'id',
            headerName: '',
            width: 120,
            renderCell: ({ row }) => (
                <TableActionButtons title={'care-plans'} actions={actions(row.id)} />
            ),
        },
    ]
}
