import {FormBuilder, MuiContentTitle} from "@VaMusaka/mui-kit";
import AuthLayout from 'components/layouts/Auth'
import {Box, Button, Typography} from "@mui/material";
import {
    signUpFormFields,
    signUpInitialValues,
    signUpValidationSchema
} from "apps/shared/pages/Auth/constants";

import React from 'react';
import {useNavigate} from "react-router-dom";
import api from "apps/shared/api";

const SignUp = () => {
    const navigate = useNavigate()
    const handleSubmit = async (data) => {
        const res = await api({
            entity: 'auth',
            action: 'signUp',
            data
        }).catch((err) => {
            console.error(err)
        })

        if(res && res.status === 204) {
            navigate('/sign-in')
        }
    }


    return <AuthLayout module={module}>
        <Box p={2}>
            <MuiContentTitle title="Sign Up to get started" />
        </Box>
        <FormBuilder
            initialValues={signUpInitialValues}
            handleSubmit={handleSubmit}
            validationSchema={signUpValidationSchema}
            formFields={signUpFormFields}
            title="Sign Up to get started"
            submitButtonText="Sign Up"
            submitFullWidth
        />

        <Box sx={{pt:  2, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <Typography sx={{pt: 0.1}} variant='body1' color='primary'>
                    Already have an account ?
                </Typography>
                <Button size="small" variant="text" color="primary" onClick={() => navigate('/sign-in')}>Sign In</Button>
            </Box>
    </AuthLayout>
}
export default SignUp;
