import React from 'react';
import PrivateRoute from "./PrivateRoute";
import * as PropTypes from "prop-types";
import DashboardLayout from "components/layouts/Dashboard";
import {Route, Routes} from "react-router-dom";
import NotFound from "components/layouts/NotFound";

DashboardLayout.propTypes = {pages: PropTypes.any};
const PrivateDashboardNavigation = ({pages}) => {
    return (

            <DashboardLayout pages={pages}>
                <Routes>
                    {
                        pages.map(({key, path, Component}) => <Route
                            key={key}
                            path={path}
                            element={
                                <PrivateRoute>
                                    <Component/>
                                </PrivateRoute>
                            }
                        />)
                    }

                    <Route
                        key={'not-found'}
                        path={'*'}
                        element={
                            <NotFound />
                        }
                    />
                </Routes>

            </DashboardLayout>

    );
}

export default PrivateDashboardNavigation;