import React, { useState } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  Button,
  Divider,
  Paper,
  Avatar, InputBase, useTheme,
} from '@mui/material'
import { styled } from '@mui/system';
import Container from "components/layouts/Container";

const drawerWidth = 260;

const Main = styled('main')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  height: '100%',
  width: '100%',
  flexGrow: 1,
  paddingLeft: theme.spacing(1),
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  margin: theme.spacing(1),
}));

const MessageInput = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  padding: theme.spacing(1),
  position: 'absolute',
  margin: theme.spacing(1),
  bottom: 0,
  width: `calc(100% - 28px)`,
  backgroundColor: theme.palette.background.paper,
}));

const ChatPage = () => {
  const [message, setMessage] = useState('');
  const theme = useTheme()

  const conversations = [
    { id: 1, type: 'dm', name: 'John Doe' },
    { id: 2, type: 'dm', name: 'Jane Smith' },
    { id: 3, type: 'channel', name: 'General' },
    { id: 4, type: 'channel', name: 'Random' },
  ];

  const messages = [
    { id: 1, sender: 'John Doe', content: 'Hello there!', timestamp: '10:30 AM' },
    { id: 2, sender: 'You', content: 'Hi John, how are you?', timestamp: '10:32 AM' },
    { id: 3, sender: 'John Doe', content: 'Im doing great, thanks!', timestamp: '10:35 AM' },
  ];

  const handleSendMessage = () => {
    // Logic to send message
    console.log('Sending message:', message);
    setMessage('');
  };

  return (
    <Container title={'Inbox'}>

    <Paper sx={{
      height: '87vh',
      display: 'flex' }}>
      <Box
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          borderRight: 'solid 1px #eee',
        }}
      >
        <Typography variant="h6" sx={{ p: 2, fontWeight: 'bold'}}>
          Chat
        </Typography>
        <Divider />
        <List>
          <ListItem>
            <ListItemText primary="Direct Messages" />
          </ListItem>
          {conversations
            .filter((conv) => conv.type === 'dm')
            .map((conv) => (
              <ListItem key={conv.id}>
                <ListItemText primary={conv.name} />
              </ListItem>
            ))}
          <Divider />
          <ListItem>
            <ListItemText primary="Channels" />
          </ListItem>
          {conversations
            .filter((conv) => conv.type === 'channel')
            .map((conv) => (
              <ListItem key={conv.id}>
                <ListItemText primary={conv.name} />
              </ListItem>
            ))}
        </List>
      </Box>


      <Main>
        {messages.map((msg) => (
          <StyledPaper key={msg.id}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Avatar sx={{
                width: 24, height: 24,
                mr: 1,
                bgColor: theme.palette.primary
              }}>
                {msg.sender[0]}
              </Avatar>

              <Typography variant="subtitle1" sx={{fontWeight: 'bold'}} >{msg.sender}</Typography>

              <Typography variant="caption" sx={{ ml: 1 }}>
                {msg.timestamp}
              </Typography>
            </Box>

            <Typography variant="body1">{msg.content}</Typography>

          </StyledPaper>
        ))}
        <MessageInput>
              <InputBase
                sx={{ width: '95%'}}
                placeholder="Type a message"
                inputProps={{ 'aria-label': 'search google maps' }}
              />

              <Box sx={{display: 'flex'}}>
                <Button
                    onClick={handleSendMessage}
                    size="small" color="primary" sx={{ p: '10px' }} aria-label="directions">
                  Send
                </Button>
              </Box>
        </MessageInput>
      </Main>
    </Paper>
    </Container>
  );
};

export default ChatPage;