import { MuiIcon} from "@VaMusaka/mui-kit";
import {Button, Tooltip} from "@mui/material";
import api from "apps/care/api";
import {tableColumns} from "apps/care/pages/CarePlans/constants";
import TableContainer from "components/bits/TableContainer";
import {useDialog} from "context/Dialog";
import React, {useLayoutEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";

const List = () => {
    const [carePlans, setCarePlans] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate()
    const dialog = useDialog()

    const fetchCarePlans = () => {
        api({
            entity: 'careplans',
            action: 'list',
        }).then(({data}) => {
            setCarePlans(data.results)
        }).finally(() => {
            setLoading(false)
        })

    }

    const tableActionCallbacks = (id) => {
        return {
            view: () => navigate(`/care-plans/view/${id}`),
            update: () => navigate(`/care-plans/update/${id}`),
            delete: () => {
                dialog.setData({
                    title: 'Confirm Delete',
                    content:
                        'Deleting care givers can not be reversed. Are you sure you want to continue?',
                    handleConfirm: () => console.log(id),
                })
                dialog.setOpen(true)
            },
        }
    }

    useLayoutEffect(() => {
        fetchCarePlans()
    }, []);

    return (
        <TableContainer
            title={'Care Plans'}
            minHeight={720}
            rows={carePlans}
            loading={loading}
            columns={tableColumns(tableActionCallbacks)}
            action={
                <Tooltip title={'Add care plans from the patient page'} >
                    <Button
                        variant="outlined"
                        onClick={() => navigate('/patients')}
                        startIcon={<MuiIcon name="AddCircleOutline" />}
                        size={'small'}
                    >
                        Patients
                    </Button>
                </Tooltip>
            }

        />

    );
}

export default List;
