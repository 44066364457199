import React from 'react';
import Container from "components/layouts/Container";
import NothingToShow from "components/bits/NothingToShow";

const Billing = () => {
    return (
        <Container title={'Billing Details'}>

            <NothingToShow />
        </Container>

    );
}

export default Billing;