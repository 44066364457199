import React from 'react'
import { FormBuilder } from '@VaMusaka/mui-kit'
import ModalPage from 'components/layouts/ModalPage'
import { noteFormFields, noteInitialValues, noteValidationSchema } from 'apps/care/pages/CareGivers/constants'
import { useNavigate, useParams } from 'react-router-dom'
import api from 'apps/care/api'
import {useSnackbar} from "context/Snackbar";

const NoteForm = ({ notes= [], postSubmit}) => {
	const {id, itemId} = useParams()
	const snackbar = useSnackbar()
	const note = notes.find((note) => note.id === itemId	)

	const editMode = itemId && note
	const path = editMode ?
		'/care-givers/view/:id/update-note/:itemId' :
		'/care-givers/view/:id/add-note'

	const navigate = useNavigate()

	const handleSubmit = (data) => {
		const submitPayload = {
			data: {
				caregiver: id,
				...data,
			},
			entity: 'caregivers',
			action: editMode ? 'updateNote' : 'addNote',
		}

		api(submitPayload)
			.then(({data}) => {
				postSubmit(data)
				snackbar.setData({
					severity: 'info',
					message: `Note ${editMode ? 'updated' : 'added' } successfully.`
				})
			})
			.catch(err => {
				console.error(err)
				snackbar.setData({
					severity: 'error',
					message: `Error ${editMode ? 'updating' : 'adding' } note.`
				})
			})

		navigate(`/care-givers/view/${id}`)
	}

	return (
		<ModalPage path={path} title={'Add Note'}>
			<FormBuilder
				title={'Add Caregiver Note'}
				initialValues={
					editMode ?
						note :
						noteInitialValues
				}
				formFields={noteFormFields}
				validationSchema={noteValidationSchema}
				handleSubmit={handleSubmit}
				submitButtonText={editMode ? 'Update Note' : 'Add Note'}
			/>
		</ModalPage>
	)
}

export default NoteForm