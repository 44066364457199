import React from 'react';
import {Box, Container} from "@mui/material";
import Jumbotron from "apps/gateway/pages/Landing/partials/Jumbotron";
import Tenants from "apps/gateway/shared/Tenants";

const Index = () => {
    return (
        <Box>

            <Container>
                <Jumbotron />

                <Box sx={{mt: 2}}>
                    <Tenants />
                </Box>

            </Container>

        </Box>
    );
};

export default Index;
