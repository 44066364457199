import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import api from "apps/care/api";
import {Avatar, Box, Grid, Paper, Typography} from "@mui/material";
import { MuiIcon } from "@VaMusaka/mui-kit";

const View = () => {
    const { id} = useParams();
    const [user, setUser] = useState({});
    const [loading, setLoading] = useState(true);

    const fetchUser = () => {
        api({entity: 'users', action: 'get', data: id })
            .then(({data}) => {
                setUser(data)
            }).catch(err => console.error(err))
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        fetchUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (loading) return ''

    return (
        <Box>
            <Grid container spacing={1} sx={{pt: 1}}>
                <Grid item xs={12} sm={6} md={6}>
                    <Paper>
                        <Box sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height:250
                        }}>

                            <Avatar src={null} alt={'user profile'} sx={{height: 50, width: 50}}/>

                            <Box sx={{
                                pt: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center'
                            }}>
                                <Typography variant='overline' color='primary'>{user.name}</Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center'
                                    }}
                                >
                                    <MuiIcon name={'Phone'} color='primary'  sx={{fontSize: 14, mr: 1 }} />
                                    <Typography variant='body1' color='primary'> Unknown </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center'
                                    }}
                                >
                                    <MuiIcon name={'Mail'} color='primary'  sx={{fontSize: 14, mr: 1 }} />
                                    <Typography variant='body1' color='primary'> {user.email} </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Paper>
                </Grid>

            </Grid>
        </Box>

    );
}

export default View;