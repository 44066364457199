import {AppLogo, MuiIcon} from "@VaMusaka/mui-kit";
import {List, ListItem, ListItemButton, ListItemIcon, ListItemText, useTheme} from "@mui/material";
import {DrawerHeader, StyledDrawer} from "components/layouts/Dashboard/Sidebar/styles";
import PropTypes from "prop-types";
import React from 'react';
import { useMatch, useNavigate} from "react-router-dom";


const MenuListItem = ({path, url, children, open}) => {
    const navigate = useNavigate();
    const match = useMatch(path)

    return <ListItemButton
        selected={Boolean(match)}
        onClick={() => {
            navigate(url)}
    }
        sx={{
            pl: 2,
            pr: 2,
            "&.Mui-selected": {
                m:2,
                borderRadius: 1,
                pl: 0,
                pr: 0,
                fontWeight: "bold",
                // backgroundColor: "#2e8b57"
            },
            ":hover": {
                ml: 1,
                mr: 1,
                borderRadius: 1,
            },
            minHeight: 48,
            justifyContent: open ? 'initial' : 'center',
            px: 2.5,
        }}
    >
        {children}
    </ListItemButton>
}

const Sidebar = ({open, pages}) => {
    const theme = useTheme()

    return (
        <StyledDrawer
            PaperProps={{
                variant: 'outlined',
                sx: {
                    position: 'fixed',
                    zIndex: theme.zIndex.appBar-1,
                    borderRadius: theme.spacing(1),
                    margin: theme.spacing(1),
                    height: `98vh`,
                    width: theme.shape.drawerWidth
                }
            }}
            variant="permanent"
            open={open}
        >
            <DrawerHeader open={open} sx={{pt: 2}}>
                {open ?
                    <AppLogo img={{src: theme.assets.logo}}  /> :
                    <AppLogo img={{src: theme.assets.logoIcon}} />
                }
            </DrawerHeader>

            <List>
                {pages.map(({title, icon, path, key, hideInMenu}) => {
                    if(hideInMenu) return null;
                    return (
                        <ListItem
                            key={key}
                            disablePadding
                            sx={{display: 'block'}}
                        >
                            <MenuListItem
                                open={open}
                                url={key}
                                path={path}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 1 : 'auto',
                                        pl:1,
                                        pr:1,
                                        justifyContent: 'center',
                                    }}
                                >
                                    <MuiIcon name={icon} color='primary'/>
                                </ListItemIcon>
                                <ListItemText primary={title} sx={{opacity: open ? 1 : 0}}/>
                            </MenuListItem>
                        </ListItem>
                    )}
                )}
            </List>
        </StyledDrawer>
    );
};

Sidebar.propTypes = {
    open: PropTypes.bool
}

Sidebar.defaultParameters = {
    open: true
}

export default Sidebar;
