import {AppLogo} from "@VaMusaka/mui-kit";
import {Box, Grid, useTheme} from "@mui/material";
import {SnackbarProvider} from "context/Snackbar";
import React from 'react';
import GatewaySlider from "./GatewaySlider";

const Auth = ({children, module}) => {
    const theme = useTheme();
    return (
        <SnackbarProvider>
            <Grid container spacing={2} justifyContent="center" alignItems="center">
                <Grid item xs={12} sm={6} md={8} sx={{
                    display: { sm: 'block'},
                }} >
                    <GatewaySlider module={module}/>
                </Grid>
                <Grid item xs={12} sm={6} md={4} >
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        margin: 'auto'
                    }}>
                        <Box sx={{p: 2, minHeight: 350, maxWidth: 350}}>
                            <Box sx={{width: 250}}>
                                <AppLogo img={{src: theme.assets.logo}} />
                            </Box>

                            {children}
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </SnackbarProvider>
    );
};

export default Auth;
