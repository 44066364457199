import axios from 'axios'

const prefix = `api/auth`

const auth = {
    signIn: async (user) => axios.post(`${prefix}/sign-in`, user),
    signUp: async (user) => axios.post(`${prefix}/sign-up`, user),
    validateInviteToken: async ({token}) => axios.get(`${prefix}/validate-invite-token/${token}`),
    activeAccount: async (user) => axios.post(`${prefix}/activate-account/${user.token}`, user),
    acceptInvite: async (user) => axios.post(`${prefix}/accept-invite/${user.token}`, user),
    verifyUser: async (user) => axios.put(`${prefix}/verify-email`, user),
    verifyEmail: async () => axios.get(`${prefix}/verify-email`),
    requestPasswordReset: async (user) => axios.post(`${prefix}/request-password-reset`, user),
    resetPassword: async (user) => axios.post(`${prefix}/reset-password/${user.token}`, user),
}

export default auth
