import React from 'react';
import ModalPage from "components/layouts/ModalPage";
import {FormBuilder} from "@VaMusaka/mui-kit";
import {FormContainer} from "components/layouts/form";
import {formFields, initialValues, validationSchema} from "../constants";
import api from "apps/gateway/api";

const Create = () => {
    const _path = '/applications/create'

    const handleSubmit = (data) => {
        api({
            entity: 'tenants',
            action: 'create',
            data
        }).then((res) => {
            console.log(res)
        }).catch(err => {
            console.log(err)
        })
    }

    return (
        <ModalPage
            path={_path}
            title='Register Application'
        >
                <FormContainer isFullWidth>
                    <FormBuilder
                        title='Register Application'
                        initialValues={initialValues}
                        formFields={formFields}
                        validationSchema={validationSchema}
                        submitButtonText={'Create Application'}
                        handleSubmit={handleSubmit}
                    >
                    </FormBuilder>
                </FormContainer>
        </ModalPage>
    );
}

export default Create;