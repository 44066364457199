import React from 'react';
import PropTypes from 'prop-types';
import CareMod from './care';
import GatewayMod from './gateway';
import GarageMod from './garage';
import NotFound from "components/layouts/NotFound";

const AppLoader = ({moduleDetails}) => {
    console.log()
    if(moduleDetails.module === 'care') return <CareMod/>
    if(moduleDetails.module === 'garage') return <GarageMod/>
    if(moduleDetails.module === 'gateway') return <GatewayMod/>

    return <NotFound />


};

AppLoader.propTypes = {
    moduleDetails: PropTypes.shape({}).isRequired,
}

export default AppLoader;
