import React from 'react';
import {MuiContentTitle} from "@VaMusaka/mui-kit";
import {Box, Button, Paper} from "@mui/material";
import {useNavigate} from "react-router-dom";
import NothingToShow from "components/bits/NothingToShow";

const Messages = () => {
    const navigate = useNavigate()

    return (
        <Paper sx={{p: 2}} elevation={3} >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <MuiContentTitle title={'Messages'}/>
                <Button
                    onClick={() => navigate('/inbox')}

                >View All</Button>
            </Box>

            <Box sx={{
                height: 150
            }}>
                <NothingToShow />
            </Box>

        </Paper>
    );
}

export default Messages;