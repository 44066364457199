import {Box, CssBaseline, Typography, useTheme} from "@mui/material";
import Header from "components/layouts/Dashboard/Header";
import Sidebar from "components/layouts/Dashboard/Sidebar";
import {LayoutProvider} from "context/Layout";
import {SnackbarProvider} from "context/Snackbar";
import dayjs from "dayjs";
import React, { useState} from 'react';

const Dashboard = ({children, pages}) => {
    const [open, setOpen] = useState(true);
    const theme = useTheme();
    const toggleDrawer = () => {
        setOpen(!open);
    }

    const innerWidth = `calc(100% - ${open ? theme.shape.drawerWidth : 0}px)`;
    const isMobileView = window.innerWidth <= 768;
    const showContent = !(isMobileView && open);

    return (
        <LayoutProvider>
            <SnackbarProvider>
                <Box sx={{display: 'flex'}}>
                    <CssBaseline />
                    <Header open={open} toggleDrawer={toggleDrawer} />


                    <Sidebar open={open} toggleDrawer={toggleDrawer} pages={pages}/>
                    <Box component="div" sx={{ pt: 13, width: innerWidth, pl: 1 }}>
                        <Box sx={{pb: 2}}>
                            {showContent && children}
                        </Box>

                        <Box sx={{
                            // position: 'absolute',
                            // bottom: theme.spacing(0),
                            // right: theme.spacing(3),
                            // width: '100%',
                            height: 25,
                            p: 2,
                            mb: 2,
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                        }}>
                            <Typography variant='overline' sx={{fontSize: 12}}> © Instantia It {dayjs().format('YYYY')}</Typography>
                        </Box>
                    </Box>
                </Box>
            </SnackbarProvider>
        </LayoutProvider>
    );
};

Dashboard.propTypes = {}

Dashboard.defaultParameters = {}
export default Dashboard;
