import React from 'react';
import {MuiDrawer} from "@VaMusaka/mui-kit";
import {useMatch, useNavigate} from "react-router-dom";
import {useTheme} from "@mui/material";
import PropTypes from "prop-types";

const ModalPage = ({children, path, title, width= 620}) => {
    const match = useMatch(path)
    const createOpen = Boolean(match)
    const theme = useTheme()
    const navigate = useNavigate()

    const isMobileView = window.innerWidth <= 768;
    const drawerWidth =  isMobileView ? (window.innerWidth - 90) : width
    const space = isMobileView ? 1 : 11

    return (
        <MuiDrawer
            title={title}
            open={createOpen}
            trigger={null}
            handleClose={() => navigate(-1)}
            radius={theme.spacing(2)}
            width={drawerWidth}
            spacing={theme.spacing(space)}
        >
            {children}
        </MuiDrawer>
    );
}

ModalPage.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.element, PropTypes.string]),
    path: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}


export default ModalPage;

