import {baseRestActions} from "lib/utilities";
import axios from 'axios'

const prefix = `api/svc/care/care-givers`

const caregivers = {
    ...baseRestActions(prefix),
    addNote:  async (data) => axios.post(`${prefix}/${data.caregiver}/notes`, data),
    updateNote:  async (data) => axios.patch(`${prefix}/${data.caregiver}/notes/${data.id}`, data),
    daleteNote:  async (data) => axios.delete(`${prefix}/${data.caregiver}/notes/${data.id}`),

}

export default caregivers
