import {FormBuilder} from "@VaMusaka/mui-kit";
import {formFields, initialValues, validationSchema} from "apps/care/pages/Patients/constants";
import {FormContainer} from "components/layouts/form";
import React from 'react';
import ModalPage from "components/layouts/ModalPage";
import api from "apps/care/api";
import { useNavigate } from 'react-router-dom'
import {useSnackbar} from "context/Snackbar";

const Create = ({postAddAction}) => {
    const path = '/patients/create'
    const navigate = useNavigate()
    const snackbar = useSnackbar()


    const handleSubmit = (data) => {

        api({entity: 'patients', action: 'create', data})
          .then(() => {
              snackbar.setData({
                  severity: 'info',
                  message: 'Patient created successfully'
              })
              navigate('/patients')
              postAddAction()
          })
          .catch(() => {
              snackbar.setData({
                  severity: 'error',
                  message: 'Error creating patients.'
              })
          })
          .finally(() => {
              snackbar.setOpen(true)
          })

    }

    return (
        <ModalPage
            title={'Create Patient'}
            path={path}
        >
            <FormContainer isFullWidth>
                <FormBuilder
                    title={'New Patient'}
                    initialValues={initialValues}
                    formFields={formFields}
                    validationSchema={validationSchema}
                    submitButtonText={'Create Patient'}
                    handleSubmit={handleSubmit}
                    />
            </FormContainer>
        </ModalPage>

    );
};

export default Create;
