import {MuiIcon} from "@VaMusaka/mui-kit";
import {Button} from "@mui/material";
import api from "apps/care/api";
import {tableColumns} from "apps/care/pages/Routes/constants";
import TableContainer from "components/bits/TableContainer";
import {useDialog} from "context/Dialog";
import React, {useLayoutEffect} from 'react';
import {useNavigate} from "react-router-dom";

const List = () => {
    const [routes, setRoutes] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const dialog = useDialog()
    const navigate = useNavigate()

    const tableActionCallbacks =  (id) => {
        return {
            view: () => navigate(`/routes/view/${id}`),
            update: () => navigate(`/routes/update/${id}`),
            delete: () => {
                dialog.setData({
                    title: 'Confirm Delete',
                    content:
                        'Deleting care givers can not be reversed. Are you sure you want to continue?',
                    handleConfirm: () => console.log(id),
                })
                dialog.setOpen(true)
            },
        }
    }

    const fetchRoutes = () => {
        api({entity: 'routes', action: 'list'})
            .then(({data}) => {
                setRoutes(data.results)
            })
            .catch(() => {
                console.error('error')
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useLayoutEffect(() => {
        fetchRoutes()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <TableContainer
            action={
                <Button
                    variant={'outlined'}
                    onClick={() => navigate('/routes/create')}
                    startIcon={<MuiIcon name={'AddCircleOutline'}/>}
                    size={'small'}
                >
                    Create New Route
                </Button>
            }
            title={'Routes'}
            minHeight={720}
            rows={routes}
            loadding={loading}
            columns={tableColumns(tableActionCallbacks)}
            />
    );
}

export default List;
