import MuiDialog from "components/bits/MuiDialog";
import React, { createContext, useCallback, useContext, useState } from 'react'

const DialogContext = createContext()

const DialogProvider = ({ children, ...rest }) => {
    const [open, setOpen] = useState()
    const [data, setData] = useState()

    const closeDialog = useCallback(() => {
        setOpen()
        setData()
    }, [setOpen])

    return (
        <DialogContext.Provider value={{ closeDialog, setData, setOpen }} {...rest}>
            {children}
            {open && <MuiDialog open={open} data={data} handleClose={closeDialog} />}
        </DialogContext.Provider>
    )
}

const useDialog = () => {
    const context = useContext(DialogContext)
    if (context === undefined) {
        throw new Error('Can not use (useDialog) outside the DialogProvider')
    }
    return context
}

export { DialogProvider, useDialog }
