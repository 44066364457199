import React, {useEffect, useState} from 'react'
import PrivateDashboardNavigation from "./PrivateDashboardNavigation";
import {useDispatch} from "react-redux";
import {decodeAuthToken, setAuthToken, setAxiosDefaults} from "lib/utilities";
import {setAuthUser} from "apps/shared/redux/authentication/slice";
import {Route, Routes} from "react-router-dom";
import NotFound from "components/layouts/NotFound";
import SignIn from "apps/shared/pages/Auth/SignIn";
import SignUp from "apps/shared/pages/Auth/SignUp";
import AcceptInvite from "apps/shared/pages/Auth/AcceptInvite";

const Navigation = ({pages, module}) => {
        const [loading, setLoading] = useState(true)
        const dispatch = useDispatch()

        const { authToken } = localStorage

        useEffect(() => {
                setAxiosDefaults(dispatch)
                //eslint-disable-next-line
        }, [])

        useEffect(() => {
                if (authToken) {
                        setAuthToken(authToken)
                        const user = decodeAuthToken(authToken)
                        dispatch(setAuthUser(Date.now() <= user.exp * 1000 ? user : null))
                        setAxiosDefaults(dispatch)
                }
                setLoading(false)
        }, [authToken, dispatch])

        if (loading) return ''

        return <Routes>
                <Route
                    element={<SignIn module={module}/>}
                    key={'sign-in'}
                    path={'sign-in/'}
                />

                <Route
                    element={<SignUp module={module}/>}
                    key={'sign-up'}
                    path={'sign-up/'}
                />

                <Route
                    element={<AcceptInvite module={module}/>}
                    key={'accept-invite'}
                    path={'accept-invite/:token'}
                />

                <Route
                    key='rest' path={'/*'}
                    element={
                            <PrivateDashboardNavigation pages={pages} module={module} />
                    } />

                <Route
                    key={'not-found'}
                    path={'*'}
                    element={
                            <NotFound />
                    }
                />



        </Routes>

}
export default Navigation
