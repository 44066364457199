const palette = {
    mode: 'light',
    background: {
        default: "#F5F5F5",
    },
    surface: {
        main: '#fafafa'
    },
    muted: {
        main: '#e0e0e0',
    },
    primary: {
        main: '#114f5a',
    },
    secondary: {
        main: '#c49450',
    },
    success: {
        main: '#00401a',
    },
}

export default palette
