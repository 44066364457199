import {DialogProvider} from "context/Dialog";
import React from 'react';
import { Provider as ReduxProvider } from 'react-redux'
import store from 'apps/care/redux/store'
import Navigation from "components/Navigation";
import {pages} from 'apps/care/constants'

const CareApp = () => {
    return (
        <ReduxProvider store={store}>
            <DialogProvider>
                <Navigation module={'care'} pages={pages} />
            </DialogProvider>
        </ReduxProvider>
    );
};

export default CareApp;
