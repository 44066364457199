import constants from './constants'
import axios from 'axios'
import {jwtDecode} from 'jwt-decode'
import { isEmpty } from 'lodash'
import {setAuthUser} from "../apps/shared/redux/authentication/slice";
// import { setAuthUser } from 'app/redux/authentication/slice'

const { REACT_APP_DOMAIN } = constants
export const hasSubDomain = () => {
    const host = window.location.hostname
    const subdomain = host.split(`.${REACT_APP_DOMAIN}`)[0]
    return subdomain.toLowerCase() !== REACT_APP_DOMAIN
}

export const getSubdomain = () => {
    const host = window.location.hostname.replace('www.', '')
    const subdomain = host.split(`.${REACT_APP_DOMAIN}`)[0]
    if (subdomain.toLowerCase() !== REACT_APP_DOMAIN) {
        return subdomain
    }

    return null
}

export const setAxiosDefaults = (dispatch) => {
    const { REACT_APP_API_PROTOCOL, REACT_APP_API_PORT, REACT_APP_API_DOMAIN } = constants
    const domain = `${hasSubDomain() ? getSubdomain() + '.' : ''}${REACT_APP_API_DOMAIN}`

    axios.defaults.baseURL = `${REACT_APP_API_PROTOCOL}://${domain}:${REACT_APP_API_PORT}`
    axios.defaults.headers.post['Content-Type'] = 'application/json'

    axios.interceptors.response.use(
        function (response) {
            return response
        },
        function (error) {
            if (error?.response?.status === 401) {
                dispatch(setAuthUser())
                window.location.href = '/#/sign-in'
            }
            return Promise.reject(error)
        }
    )
}

export const setAuthToken = (token) => {
    if (token) {
        axios.defaults.headers.common['Authorization'] = token
    } else {
        delete axios.defaults.headers.common['Authorization']
    }
}

export const signOut = (dispatch) => {
    setAuthToken()
    localStorage.removeItem('authToken')
    dispatch(setAuthUser())
    window.location.href = '/#/sign-in'
}

export const decodeAuthToken = (token) => {
    return jwtDecode(token)
}

export const getTenantUrl = (domain) => {
    const { REACT_APP_API_PROTOCOL, REACT_APP_DOMAIN, REACT_APP_PORT } = constants
    const port = REACT_APP_PORT !== 443 || REACT_APP_PORT !== 80 ? `:${REACT_APP_PORT}` : ''
    return `${REACT_APP_API_PROTOCOL}://${domain}.${REACT_APP_DOMAIN}${port}`
}

export const generateBreadcrumbs = ({ icon, name, pathname, action, label }) => {
    const initialLink = pathname.split('/')[1]
    const actions = {
        search: { icon: 'Search', label: `Search ${name}`, link: `/#${pathname}` },
        create: { icon: 'AddCircleOutline', label: `Create New`, link: `/#${pathname}` },
        update: { icon: 'DriveFileRenameOutline', label: `Update`, link: `/#${pathname}` },
        view: { label, link: `/#${pathname}` },
    }

    const breadcrumbs = [
        {
            label: name,
            icon,
            link: `/#/${initialLink}`,
        },
    ]

    if (action) breadcrumbs.push(actions[action])

    return breadcrumbs
}

export const makeDropDownOptions = (data, labelField, valueField) => {
    if (!data || isEmpty(data)) {
        return []
    }

    return data?.map((item) => {
        return { value: item[valueField], label: item[labelField] }
    })
}

export const api =  (entities) => {
    return  ({ entity, action, data }) => entities[entity][action](data)
}

export const baseRestActions = (prefix) => {
    return {
        ping: async () => axios.get(`${prefix}/ping`),
        list: async () => axios.get(`${prefix}/`),
        get: async (id) => axios.get(`${prefix}/${id}`),
        create: async (data) => axios.post(`${prefix}/`, data),
        update: async (data) => axios.put(`${prefix}/${data.id}`, data),
        delete: async (id) => axios.delete(`${prefix}/${id}`),
    }
}

export const completeAuthentication = (token, dispatch, navigate) => {
    localStorage.setItem('authToken', token)

    const user = decodeAuthToken(token)
    setAuthToken(token)
    dispatch(setAuthUser(user))
    navigate('/')
}