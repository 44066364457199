import Landing from './pages/Landing/Landing'
import Applications from "./pages/Applications";
import Billing from "./pages/Billing";
import Subscriptions from "./pages/Subscriptions";

export const pages = [

    {
        key:'/',
        layout: 'dashboard',
        Component: Landing,
        requireAuth: true,
        title: 'Getting Started',
        path: '/',
        icon: 'Home',
        description: null,
    },{
        key:'/applications',
        layout: 'dashboard',
        Component: Applications,
        requireAuth: true,
        title: 'Applications',
        path: '/applications/*',
        icon: 'AppRegistration',
        description: null,
    },

    {
        key:'/billing',
        layout: 'dashboard',
        Component: Billing,
        requireAuth: true,
        title: 'Billing',
        path: '/billing/*',
        icon: 'Payments',
        description: null,
    },
    {
        key:'/subscriptions',
        layout: 'dashboard',
        Component: Subscriptions,
        requireAuth: true,
        title: 'Subscriptions',
        path: '/subscriptions/*',
        icon: 'Paid',
        description: null,
    }
]