import React from 'react';
import {Paper} from "@mui/material";
import RoutesList from "apps/care/pages/Scheduler/RoutesList/RoutesList";
import PropTypes from "prop-types";

const RoutesListCard = ({showRedirect}) => {
    return (
        <Paper sx={{pt: 1, pl: 2, pr: 2 }} elevation={3} >
           <RoutesList showHeaderAction={showRedirect}/>
        </Paper>
    );
}

RoutesListCard.propTypes = {
    showRedirect: PropTypes.bool
};

RoutesListCard.defaultParameters = {
    showRedirect: false
}

export default RoutesListCard;
