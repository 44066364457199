import {FormBuilder} from "@VaMusaka/mui-kit";
import AuthLayout from 'components/layouts/Auth'
import {Box, Button, Typography} from "@mui/material";
import api from "apps/shared/api";
import {signInFormFields, signInValidationSchema} from "apps/shared/pages/Auth/constants";
import { hasSubDomain, completeAuthentication} from "lib/utilities";
import React from 'react';
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";

const SignIn = ({module}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const handleSubmit = async (data) => {
        const res = await api({
            entity: 'auth',
            action: 'signIn',
            data,
        }).catch((err) => {
            console.error(err)
        })

        completeAuthentication(res.data.token, dispatch, navigate)
    }

    return <AuthLayout module={module}>
        <FormBuilder
            initialValues={{
                email: '',
                password: '',
            }}
            handleSubmit={handleSubmit}
            validationSchema={signInValidationSchema}
            formFields={signInFormFields}
            title="Sign in to get started"
            submitButtonText="Sign In"
            submitFullWidth
        />
        {!hasSubDomain() && (
            <Box sx={{pt:  2, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <Typography sx={{pt: 0.1}} variant='body1' color='primary'>
                    Do not have an account ?
                </Typography>
                <Button size='small' variant="text" color="primary" onClick={() => navigate('/sign-up')}>
                    Sign Up
                </Button>
            </Box>
        )}
    </AuthLayout>
}



export default SignIn;
