import {AppBar} from "@mui/material";
import {styled} from "@mui/material/styles";

export const MuiAppBar = styled(AppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open,  }) => ({
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    borderRadius: theme.spacing(1),
    margin: theme.spacing(1),
    marginTop: theme.spacing(2),
    ...(open ? {
        marginLeft: theme.shape.drawerWidth,
        width: `calc(100% - ${theme.shape.drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        })
    }: {
        marginRight: theme.spacing(1),
        width: `calc(100% - ${theme.spacing(11)} + 0.5px)`,
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${theme.spacing(10)} + 0.5px)`,
        },
    }),
}));
