import {Alert} from "@mui/lab";
import {Snackbar} from "@mui/material";
import React, {createContext, useCallback, useContext, useState} from 'react';

const SnackbarContext = createContext()

const SnackbarProvider = ({children}) => {
    const [open, setOpen] = useState();
    const [data, setData] = useState()

    const handleClose = useCallback(() => {
        setOpen()
        setData()
    }, [setOpen])


    return (
        <SnackbarContext.Provider value={{setOpen, setData}}>
            {children}

            {data &&
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                    <Alert
                        onClose={handleClose}
                        severity={data.severity}
                        variant="filled"
                        sx={{width: '100%'}}
                    >
                        {data.message}
                    </Alert>
                </Snackbar>
            }
        </SnackbarContext.Provider>
    )
}

const useSnackbar = () => {
    const context = useContext(SnackbarContext)
    if (!context) {
        throw new Error('useSnackbar must be used within an SnackbarProvider');
    }
    return context;
}

export { SnackbarProvider, useSnackbar };
