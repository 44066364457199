import React from 'react'
import { Grid } from '@mui/material'

const Container = ({ children, isFullWidth }) => {
    const size = isFullWidth ? { xs: 12 } : { xs: 12, sm: 10, md: 8, lg: 6 }

    const gutter = isFullWidth ? { xs: 0 } : { xs: 0, sm: 1, md: 2, lg: 3 }

    return (
        <Grid container>
            <Grid item {...gutter} />
            <Grid item {...size}>
                {children}
            </Grid>
        </Grid>
    )
}

export default Container
