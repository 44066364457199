import * as Yup from 'yup'
export const signInFormFields = [
    {
        component: 'MuiTextField',
        type: 'email',
        name: 'email',
        label: 'Email',
    },
    {
        component: 'MuiTextField',
        type: 'password',
        name: 'password',
        label: 'Password',
    },
]

export const signInValidationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid Email').required('Email is Required'),
    password: Yup.string().min(12, 'Password must be more than 12 Characters long.'),
})


export const signUpInitialValues = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: '',
}

export const signUpValidationSchema = Yup.object().shape({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    email: Yup.string().required(),
    phone: Yup.string().required(),
    password: Yup.string().required(),
    passwordConfirmation: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required(),
})

export const signUpFormFields = [
    {
        component: 'MuiTextField',
        name: 'firstName',
        label: 'First Name',
        placeholder: '',
    },    {
        component: 'MuiTextField',
        name: 'lastName',
        label: 'Last Name',
        placeholder: '',
    },
    {
        component: 'MuiTextField',
        name: 'email',
        label: 'Email Address',
        placeholder: '',
    },
    {
        component: 'MuiTextField',
        name: 'phone',
        label: 'Mobile Phone',
        placeholder: '',
    },
    {
        component: 'MuiTextField',
        name: 'password',
        label: 'Password',
        type: 'password',
        placeholder: '',
    },
    {
        component: 'MuiTextField',
        name: 'passwordConfirmation',
        label: 'Confirm Password',
        type: 'password',
        placeholder: '',
    },
]
