import {useLayoutEffect, useState} from "react";
import axios from "axios";
import {HashRouter as Router} from "react-router-dom";
import AppLoader from "./apps/AppLoader";
import {setAxiosDefaults} from "lib/utilities";
import './App.css';
import theme from "./theme";
import {ThemeProvider} from "@mui/material";

function App() {
  const [loading, setLoading] = useState(true);
  const [moduleDetails, setModuleDetails] = useState({})

  const loadModuleDetails =  () => {
    axios.get('/api/about')
        .then(res => {
          setModuleDetails(res?.data || {})
        })
        .catch(err => {
          console.error('Module not found loading Gateway')
        })
        .finally(() => {
          setLoading(false)
        })
  }

  useLayoutEffect(() => {
    setAxiosDefaults()
    loadModuleDetails()
  }, []);


  if(loading) return ''


  return (
      <ThemeProvider theme={theme}>
          <Router>
              <AppLoader moduleDetails={moduleDetails}/>
          </Router>
      </ThemeProvider>
  );
}

export default App;
