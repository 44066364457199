import React, {useLayoutEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {useDialog} from "context/Dialog";
import api from "apps/care/api";
import TableContainer from "components/bits/TableContainer";
import {Button} from "@mui/material";
import {MuiIcon} from "@VaMusaka/mui-kit";
import {tableColumns} from 'apps/care/pages/Users/constants'

const List = () => {
    const [users, setUsers] = useState([])
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const dialog = useDialog()

    const fetchUsers = () => {
        api({
            entity: 'users',
            action: 'list'
        })
            .then(({data}) => {
                setUsers(data.results || [])
            })
            .catch(err => console.error(err))
            .finally(() => setLoading(false))

    }

    const tableActionCallbacks = (id) => {
        return {
            view: () => navigate(`/users/view/${id}`),
            update: () => navigate(`/users/update/${id}`),
            delete: () => {
                dialog.setData({
                    title: 'Confirm Delete',
                    content:
                        'Deleting user can not be reversed. Are you sure you want to continue?',
                    handleConfirm: () => console.log(id),
                })
                dialog.setOpen(true)
            },
        }
    }

    useLayoutEffect(() => {
        fetchUsers()
    }, []);



    return (
        <TableContainer
            action={
                <Button
                    variant='outlined'
                    onClick={() => navigate('/user/invite')}
                    startIcon={<MuiIcon name="AddCircleOutline" />}
                    size='small'
                >
                    Invite User
                </Button>
            }
            title='All Users'
            minHeight='75vh'
            columns={tableColumns(tableActionCallbacks)}
            rows={users}
            loading={loading}
        />
    );
}

export default List;