import {MuiIcon} from "@VaMusaka/mui-kit";
import {Button} from "@mui/material";
import api from "apps/care/api";
import {tableColumns} from "apps/care/pages/Patients/constants";
import Create from "apps/care/pages/Patients/Create";
import TableContainer from "components/bits/TableContainer";
import {useDialog} from "context/Dialog";
import React, {useLayoutEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";

const List = () => {
    const [loading, setLoading] = useState(true);
    const [patients, setPatients] = useState([]);
    const navigate = useNavigate()
    const dialog = useDialog()

    const fetchPatients = () => {
        api({entity: 'patients', action: 'list'})
            .then(({data}) => {
                    setPatients(data.results)
                }
            )
            .catch(err => {
                console.error(err)
            }).finally(() => {
            setLoading(false)
        })
    }


    const tableActionCallbacks = (id) => {
        return {
            view: () => navigate(`/patients/view/${id}`),
            update: () => navigate(`/patients/update/${id}`),
            delete: () => {
                dialog.setData({
                    title: 'Confirm Delete',
                    content:
                        'Deleting care givers can not be reversed. Are you sure you want to continue?',
                    handleConfirm: () => console.log(id),
                })
                dialog.setOpen(true)
            },
        }
    }

    useLayoutEffect(() => {
        fetchPatients()
    }, []);

    return (
        <>
            <Create postAddAction={fetchPatients}/>
            <TableContainer
                title={'Patients'}
                minHeight={720}
                rows={patients}
                loading={loading}
                columns={tableColumns(tableActionCallbacks)}
                action={
                    <Button
                        variant="outlined"
                        onClick={() => navigate('/patients/create')}
                        startIcon={<MuiIcon name="AddCircleOutline" />}
                        size={'small'}
                    >
                        Add New Patient
                    </Button>
                }
            />

        </>
    );
};

export default List;
