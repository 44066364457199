import React from 'react';
import Container from "components/layouts/Container";
import {Route, Routes} from "react-router-dom";
import List from "./List";
import View from "./View";

const Users = () => {
    return (
        <Container title={'Users'}>
            <Routes>
                <Route key={'list-users'} path={'/'} element={<List />}/>
                <Route key={'view-users'} path={'/view/:id'} element={<View />}/>
            </Routes>
        </Container>
    );
}

export default Users;