import TableActionButtons from "components/bits/TableActionButtons";
import dayjs from "dayjs";

export const tableColumns = (actions) => {
    return [
        {
            field: 'name',
            headerName: 'Route Name',
            width: 200,
        },
        {
            field: 'created_at',
            headerName: 'Created Date',
            width: 200,
            valueGetter: ({ row }) => `${dayjs(row.created_at).fromNow()}`,
        },
        {
          field: 'patients',
          headerName: 'Patients',
          valueGetter: ({row}) => `${row.patients.length} patients`,
        },
        {
            field: 'id',
            headerName: '',
            width: 120,
            renderCell: ({ row }) => (
                <TableActionButtons title={'care givers'} actions={actions(row.id)} />
            ),
        },
    ]
}
