import React from 'react';
import Container from "components/layouts/Container";
import {Route, Routes} from "react-router-dom";
import ListApplications from "./List";
import ViewApplication from "./View";


const Applications = () => {
    return (
        <Container title={'Applications'}>
            <Routes>
                <Route key={'list-tenants'} path={'/'} element={<ListApplications />} />
                <Route key={'create-tenant'} path={'/create'} element={<ListApplications />} />
                <Route key={'view-tenant'} path={'/view/:id'} element={<ViewApplication />} />
            </Routes>
        </Container>
    );
}

export default Applications;