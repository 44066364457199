import { alpha } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import { makeMiscHelpers } from './helpers'
import MuiTypography from './typography'
import palette from "./palette";

const drawerWidth = 300

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    toPrimaryGradient: {
        backgroundImage: `linear-gradient(to top, ${palette.primary.main}, #ffffff)`,
    },
    fromPrimaryGradient: {
        backgroundImage: `linear-gradient(to top, #1B7FF1, ${palette.primary.main})`,
    },
    menuDropDown: {
        overflow: 'visible',
        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
        mt: 2.5,
        padding: 0,
        '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
        },
        '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
        },
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        height: 67,
        justifyContent: 'center',
        // position: 'absolute',
        borderRadius: theme.shape.borderRadius,
    },
    styledDrawer: {
        top: '120px !important',
        right: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
    },
    styledMenuBar: {
        [theme.breakpoints.up('md')]: {
            borderTopLeftRadius: theme.shape.borderRadius,
            borderTopRightRadius: theme.shape.borderRadius,
            left: theme.spacing(3),
            top: theme.spacing(1),
            border: `solid 5px ${theme.palette.primary.main} !important `,
        },
    },
    styledSelectedMenuItem: {
        backgroundColor: `${theme.palette.grey[200]} !important`,
        fontWeight: 600,
        // color: `${theme.palette.primary.darker} !important`,
        // textTransform: 'uppercase',
        // marginTop: theme.spacing(1),
        // marginBottom: theme.spacing(1),
        // border: `solid ${theme.spacing(0.1)} ${theme.palette.grey[500_80]}`,
        borderRadius: theme.spacing(1),
    },
    styledSelectedMenuItemContent: {
        fontWeight: `600 !important`,
        color: `${theme.palette.primary.darker} !important`,
    },
    styledSelectedMenuItemHeader: {
        textTransform: 'uppercase',
        fontWeight: `bold !important`,
    },
    layoutContainer: {
        marginTop: theme.spacing(2) * 4,
        [theme.breakpoints.up('sm')]: {
            marginLeft: drawerWidth,
            width: 'auto',
        },
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    rounded: {
        borderRadius: theme.shape.borderRadius,
    },
    logoText: {
        fontFamily: "'Faster One', cursive !important",
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    /// PRODUCTS
    productPageContainer: {
        marginTop: 100,
        padding: theme.spacing(2),
    },
    productGalleryContainer: {
        [theme.breakpoints.up('sm')]: {
            height: 510,
        },
        [theme.breakpoints.down('sm')]: {
            height: 280,
        },
    },
    productDescriptionContainer: {
        overflowY: 'scroll',
        height: 160,
    },
    productTitleContainer: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        maxHeight: 50,
    },
    hoverOverlay: {
        height: '100%',
        width: '100%',
        backgroundColor: theme.palette.primary.main,
        opacity: 0.8,
    },
    rowSpaceBetween: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    rowCenterContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    columnCenterContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    columnLeftContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        justifyContent: 'center',
        height: '100%',
    },
    rowLeftContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'left',
    },
    centerContent: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    centerContentInline: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    pointer: { cursor: 'pointer' },
    defaultFont: {
        fontFamily: `${MuiTypography.fontFamily[0]} !important`,
    },
    subtleCard: {
        borderRadius: 1,
        backgroundColor: '#fafafa',
        p: 2,
        display: 'flex',
        flexDirection: 'column',

    },
    clickable: {
        cursor:'pointer'
    },
    ...makeMiscHelpers(theme),
}))

export default useStyles
