import React from 'react';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import {Box} from "@mui/material";
import CareBg from 'apps/care/assets/isometric/cancer_nurse.svg'
import Isometric_01 from 'assets/images/isometric/isometric_01.jpeg'
import Isometric_02 from 'assets/images/isometric/isometric_02.png'


const AuthBg = ({img}) => <Box
    sx={{
        backgroundImage: `url(${img})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderRadius: {sx: 0, sm: 5},
        overflow: 'hidden',
        height: {xs: '20vh', sm : '90vh', }
    }}
/>

const GatewaySlider = ({module}) => {
    const moduleImages = {
        care: CareBg,
        gateway: Isometric_01,
        garage: Isometric_02
    }

    return (
        <Box
            sx={{
                m: {sx: 2 , sm: 5},
            }}
        >
            <Slider
                dots={false}
                infinite={true}
                fade={true}
                speed={10000}
                slidesToShow={1}
                autoplay={true}
                slidesToScroll={1}
            >
                {
                     module === 'gateway' ?
                         Object.keys(moduleImages).map((mod, i) =>
                             <AuthBg img={moduleImages[mod]} key={mod}/>
                         )
                         :
                         <AuthBg img={moduleImages[module]} />
                }
            </Slider>
        </Box>
    );
}

export default GatewaySlider;