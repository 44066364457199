import React from 'react';
import {Container as MuiContainer} from "@mui/material";
import Container from "components/layouts/Container";
import Tenants from 'apps/gateway/shared/Tenants';


const ListApplications = () => {
    return (
        <Container title={'Applications'}>
            <MuiContainer>
                <Tenants />
            </MuiContainer>
        </Container>
    );
}

export default ListApplications;