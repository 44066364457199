import api from "apps/care/api";
import RoutesList from "apps/care/pages/Scheduler/RoutesList/RoutesList";
import React, {useEffect, useState} from 'react';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import {
    Grid,
    Paper,
} from "@mui/material";
import Container from "components/layouts/Container";


import dayjs from "dayjs";
const isoWeek = require('dayjs/plugin/isoWeek')
dayjs.extend(isoWeek)

const Scheduler = () => {
    const [selectedWeekEvents, setSelectedWeekEvents] = useState([])
    const [ , setSelectedWeekDates] = useState(null);
    const [selectedDate, ] = useState(dayjs());
    const weekNo = dayjs(selectedDate).isoWeek();

    const fetchWeekSchedule = () => {
        api({entity:'schedule', action: 'list', data: {weekNo}})
        .then(({data}) => {
            console.log(data)
            setSelectedWeekEvents(data)
        })
            .catch((err) => {
                console.log('error', err)
            })

    }

    const getDatesForWeek = () => {
        const date = dayjs(selectedDate);
        const startOfWeek = date.startOf('week');
        const weekDates = [];
        for (let i = 0; i < 7; i++) {
            const currentDate = startOfWeek.add(i, 'day');
            weekDates.push(currentDate.format('YYYY-MM-DD'));
        }
        return weekDates;
    }

    const handleRouteSelect = (route) =>{
        console.log(route)
    }

    useEffect(() => {
        const weekDates = getDatesForWeek()
        setSelectedWeekDates(weekDates)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDate])


    useEffect(() => {
        fetchWeekSchedule()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [weekNo]);

    const events = selectedWeekEvents.map(_event => {
            return {
                title: _event.route.name,
                start: `${dayjs(_event.date).format('YYYY-MM-DD')}T${_event.earliestStartTime}:00`,
                end: `${dayjs(_event.date).format('YYYY-MM-DD')}T${_event.latestEndTime}:00`,
                color: _event.route.color,
            }
    }) || []

    return (
        <Container title={'Scheduler'}>
            <Paper sx={{p: 2, marginBottom: 5, height: '85vh'}} elevation={3} >
                <Grid container spacing={1}>
                    <Grid item lg={3} md={4} sm={6} xs={12} sx={{
                        mt: 4
                    }}>
                       <RoutesList handleSelect={handleRouteSelect} />
                    </Grid>

                    <Grid item lg={9} md={8} sm={6} xs={12}>

                        {/*<MuiContentTitle title={`Week #${weekNo}`} />*/}
                        {/*<WeekSelector selectedDate={selectedDate} handleChange={(newValue) => setValue(newValue)} />*/}

                        <FullCalendar
                            plugins={[ dayGridPlugin, timeGridPlugin]}
                            initialView="dayGridMonth"
                            height='80vh'
                            events={events}
                            headerToolbar={{
                                start: 'dayGridMonth,timeGridWeek,timeGridDay',
                                center: 'title',
                                end: 'today,prev,next'
                            }}
                            titleFormat={{
                                year: 'numeric', month: 'short', day: 'numeric'

                            }}

                        />

                        {/*
                            <WeekSchedule selectedWeekDates={selectedWeekDates} selectedWeekEvents={selectedWeekEvents} />
                        */}
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
};

export default Scheduler;
