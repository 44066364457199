import {Box, Drawer} from "@mui/material";
import {styled} from "@mui/material/styles";

const openedMixin = (theme) => ({
    width: theme.shape.drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(10)} + 0.5px)`,

    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(9)} + 0.5px)`,
    },
});

export const DrawerHeader = styled(Box, { shouldForwardProp: (prop) => prop !== 'open' } )(({ theme, open, drawerWidth }) => ({
    zIndex: theme.zIndex.appBar - 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(1),
    ...(open && {width: `200px`}),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));



export const StyledDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: theme.shape.drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);
