import {MuiContentTitle} from "@VaMusaka/mui-kit";
import { Box, Paper, Typography, useTheme} from "@mui/material";
import CareGiversListCard from "apps/care/pages/Home/Partials/CareGiversListCard";
import KpiRow from "apps/care/pages/Home/Partials/KpiRow";
import PatientsListCard from "apps/care/pages/Home/Partials/PatientsListCard";
import RoutesListCard from "apps/care/pages/Home/Partials/RoutesListCard";
import Container from "components/layouts/Container";
import React from 'react'
import {Masonry} from '@mui/lab'
import Messages from "apps/care/pages/Home/Partials/Messages";

const Home = () => {
    const theme = useTheme()
    return (
        <Container title={'Dashboard'}>

            <KpiRow />

            <Masonry columns={{
                xs: 1, sm: 2, md: 3
            }} spacing={1}>
                <Paper sx={{p: 2}} elevation={3} >
                    <MuiContentTitle title={'Todays Schedule'} color={'primary'}/>

                    {
                        [1,2,3].map(index =>
                            <Paper key={index} sx={{p: 1, m: 1}}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}>
                                    <Typography variant={'overline'}>Sinfin Morning Route</Typography>
                                    <Typography variant={'body1'} sx={{fontSize: theme.spacing(1.5)}}>08:00 - 12.00</Typography>
                                </Box>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}>
                                    <Typography variant={'body1'} sx={{fontSize: theme.spacing(1.5)}}>5 Patients (stops)  </Typography>
                                    <Typography variant={'body1'} sx={{fontSize: theme.spacing(1.5)}}>2 Care Givers</Typography>
                                </Box>
                            </Paper>
                        )
                    }
                </Paper>

                <RoutesListCard  showRedirect={true}/>


                <PatientsListCard />


                <CareGiversListCard />

                <Messages />
            </Masonry>
        </Container>
    );
};

export default Home;
