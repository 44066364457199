import TableActionButtons from "components/bits/TableActionButtons";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import * as Yup from "yup";
dayjs.extend(relativeTime)

export const initialValues = {
    firstName: '',
    lastName: '',
    dateOfBirth: dayjs().subtract(30, 'years'),
    gender:'',
    contactNumber:'',
    medicalHistory:''
}

export const formFields = [
    {
        component: 'MuiTextField',
        name: 'firstName',
        label: 'First Name',
    },
    {
        component: 'MuiTextField',
        name: 'lastName',
        label: 'Last Name',
    },
    {
        component: 'MuiSelectField',
        options: [
            {
                label: 'Male',
                value: 'Male'
            },
            {
                label: 'Female',
                value: 'Female'
            },
            {
                label: 'Other',
                value: 'Other'
            }],
        name: 'gender',
        label: 'Gender',
    },
    {
        component: 'MuiTextField',
        name: 'contactNumber',
        label: 'Patient Contact Number',
    },
    {
        component: 'MuiDateField',
        name: 'dateOfBirth',
        label: 'Date of birth',
    },
    {
        component: 'MuiTextField',
        name: 'medicalHistory',
        label: 'Brief Medical History',
        multiline: true,
        rows: 4
    }
]


export const validationSchema =  Yup.object().shape({
    firstName: Yup.string().min(3).required('First Name is required'),
    lastName: Yup.string().min(3).required('Last Name is required'),
    dateOfBirth: Yup.date(),
    gender: Yup.string(),
    contactNumber:Yup.string().min(10).max(15).required('Contact Number is required'),
    medicalHistory: Yup.string()
})


export const tableColumns = (actions) => {
    return [
        {
            field: 'firstName',
            headerName: 'First Name',
            width: 200,
        },{
            field: 'lastName',
            headerName: 'Last Name',
            width: 200,
        }, {
            field: 'dateOfBirth',
            headerName: 'Age',
            width: 120,
            valueGetter: ({ row }) => `${dayjs(row.dateOfBirth).fromNow(true)}`,
        }, {
            field: 'gender',
            headerName: 'Gender',
            width: 120,
        },
        {
            field: 'id',
            headerName: '',
            width: 220,
            renderCell: ({ row }) => (
                <TableActionButtons title={'patients'} actions={actions(row.id)} />
            ),
        },
    ]
}
