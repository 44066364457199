import * as  Yup from 'yup';
export const validationSchema = Yup.object().shape({
    name: Yup.string().required('Application / Organisation Name is required'),
    domain: Yup.string().required('Application Domain is required'),
    module: Yup.string().required('Please Select application type'),
})

export const initialValues = {
    name: '',
    domain: '',
    module: 'care'
}

export const formFields = [
    {
        component: 'MuiTextField',
        name: 'name',
        label: 'Organisation Name',
    },
    {
        component: 'MuiTextField',
        name: 'domain',
        label: 'Domain',
    },
    {
        component: 'MuiSelectField',
        name: 'module',
        label: 'Application Type',
        options: [
            {
                label: 'Care Maneja',
                value: 'care'
            },
            {
                label: 'Garage Maneja',
                value: 'garage'
            },
            {
                label: 'Estate Maneja',
                value: 'estate'
            }],
    },

]