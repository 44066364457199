import React from 'react';
import {Button} from "@mui/material";

const OpenTenantTab = ({tenant}) => {
    const handleOpenApplication = (subdomain) => {
        const {hostname, protocol, port} = window.location
        return window.open(`${protocol}//${subdomain}.${hostname}:${port}/`)
    }

    return (
        <Button
            variant={'outlined'}
            size='small'
            onClick={() => handleOpenApplication(tenant.domain)}
        >
            Open Application
        </Button>
    );
}

export default OpenTenantTab;