import React, { createContext, useContext, useState } from 'react'

const LayoutContext = createContext({
    headerTitle: ''
})

const LayoutProvider = ({ children, ...rest }) => {
    const [data, setData] = useState()

    return (
        <LayoutContext.Provider value={[data, setData]} {...rest}>
            {children}
        </LayoutContext.Provider>
    )
}

const useLayout = () => {
    const context = useContext(LayoutContext)
    if (context === undefined) {
        throw new Error('Can not use (useLayout) outside a the LayoutProvider')
    }
    return context
}

export { LayoutProvider, useLayout }
