import React from 'react'
import {Box, Grid, Paper, Typography} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'

const TableContainer = ({ minHeight, hideFooter, title, action, rows, ...rest }) => {
    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 10,
        page: 0,
    })

    const height = minHeight || '85vh'

    return (
        <Paper sx={{p:2}} elevation={3}>
            {(title || action) && (
                <Grid container
                      spacing={1}
                >
                    <Grid item sm={6} xs={12}>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                pl:2
                            }}
                        >
                            <Typography
                            sx={{
                                fontSize: '0.8125rem',
                            }}
                            variant="overline" color={'primary'}>{title}</Typography>
                        </Box>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <Box
                            sx={{
                                mb:1,
                                display: 'flex',
                                justifyContent: 'flex-end',
                                pr: 2
                            }}
                        >
                            {action}
                        </Box>
                    </Grid>
                </Grid>
            )}

            <Box sx={{ height }}>
                <DataGrid
                    autoPageSize
                    rowHeight={40}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    rowsPerPageOptions={[10, 20]}
                    experimentalFeatures={{ newEditingApi: true }}
                    disableSelectionOnClick
                    hideFooter={hideFooter}
                    rows={rows || []}
                    {...rest}
                />
            </Box>
        </Paper>
    )
}


export default TableContainer
