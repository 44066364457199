import {createTheme} from '@mui/material/styles'
import LogoIcon from "apps/care/assets/images/Icon.png";
import Logo from "apps/care/assets/images/Logo.png";
import AuthBg from "apps/care/assets/isometric/cancer_nurse.svg";
import palette from "theme/palette";
import typography from './typography'

import shadows from "theme/shadows";

const theme = createTheme({
    shadows,
    palette,
    typography,
    assets: {
        logo: Logo,
        logoIcon: LogoIcon,
        authBg: AuthBg
    },
    shape: {
        borderRadius: 8,
        drawerWidth: 250
    },
    overrides: {
        MuiAppBar: {
            colorInherit: {
                backgroundColor: '#2F4F4F',
                color: '#fff',
            },
        },
    },
    components:{
        MuiPaper: {
            defaultProps: {
                elevation: 0,
                rounded: 'true',
                variant: 'outlined',
            },
            styleOverrides: {
                root: {
                    padding: 1,
                },
            },
        },
    },
    props: {
        MuiAppBar: {
            color: 'inherit',
        },
        MuiList: {
            dense: true,
        },
        MuiMenuItem: {
            dense: true,
        },
        MuiTable: {
            size: 'small',
        },
        MuiInput:{
            size: 'small'
        }
    },
    spacing: 8,

})


export default theme
