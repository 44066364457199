
import React from 'react';
import List from "apps/care/pages/CareGivers/List";
import View from "apps/care/pages/CareGivers/View";
import Container from "components/layouts/Container";
import { Route, Routes } from 'react-router-dom'



const CareGivers = () => {
    return (
        <Container title={'Care Givers'}>
            <Routes>
                <Route key={'list-care-givers'} path={'/'} element={<List/>} />
                <Route key={'list-care-givers'} path={'/create'} element={<List/>} />
                <Route key={'view-care-giver'} path={'/view/:id'} element={<View/>} />
                <Route key={'update-care-giver'} path={'/update/:id'} element={<View />} />
                <Route key={'view-care-giver-add-note'} path={'/view/:id/add-note'} element={<View />} />
                <Route key={'view-care-giver-update-note'} path={'/view/:id/update-note/:itemId'} element={<View />} />
                <Route key={'view-care-giver-view-note'} path={'/view/:id/view-note/:itemId'} element={<View />} />

            </Routes>
        </Container>
        );
};

export default CareGivers;
