import List from "apps/care/pages/CarePlans/List";
import Container from "components/layouts/Container";
import React from 'react';
import {Route, Routes} from "react-router-dom";

const CarePlans = () => {
    return (
        <Container title={'Care Plans'}>
           <Routes>
               <Route key={'list-care-plans'} path={'/'} element={<List/>}/>
           </Routes>
        </Container>
    );
}

export default CarePlans;
