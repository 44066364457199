import { MuiIcon} from "@VaMusaka/mui-kit";
import {Button} from "@mui/material";
import {tableColumns} from "apps/care/pages/CareGivers/constants";
import api from "apps/care/api";
import Create from "apps/care/pages/CareGivers/Create";
import TableContainer from "components/bits/TableContainer";
import {useDialog} from "context/Dialog";
import React, { useLayoutEffect, useState} from 'react';
import { useNavigate} from "react-router-dom";

const List = () => {
    const [caregivers, setCaregivers] = useState([])
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()
    const dialog = useDialog()

    const fetchCaregivers = () => {
        api({entity: 'caregivers', action: 'list'})
            .then(({data}) => {
                setCaregivers(data?.results || [])
            })
            .catch(err => {
                console.error(err)
            }).finally(() => {
                setLoading(false)
        })

    }

    const tableActionCallbacks = (id) => {
        return {
            view: () => navigate(`/care-givers/view/${id}`),
            update: () => navigate(`/care-givers/update/${id}`),
            delete: () => {
                dialog.setData({
                    title: 'Confirm Delete',
                    content:
                        'Deleting care givers can not be reversed. Are you sure you want to continue?',
                    handleConfirm: () => console.log(id),
                })
                dialog.setOpen(true)
            },
        }
    }


    useLayoutEffect(() => {
        fetchCaregivers()
    },[])

    return (
        <>
            <Create postAddAction={fetchCaregivers}/>
            <TableContainer
                action={
                    <Button
                        variant="outlined"
                        onClick={() => navigate('/care-givers/create')}
                        startIcon={<MuiIcon name="AddCircleOutline" />}
                        size={'small'}
                    >
                        Add New Care Giver
                    </Button>
                }
                title={'All Care Givers'}
                minHeight={720}
                columns={tableColumns(tableActionCallbacks)}
                rows={caregivers}
                loading={loading}

            />
        </>
    );
};

export default List;
