import {MuiIcon} from "@VaMusaka/mui-kit";
import {Avatar, Box, IconButton, Typography, useTheme} from "@mui/material";
import PropTypes from "prop-types";
import React from 'react';

const SubtleListItem = ({primaryText, handleSelect, avatar, icon}) => {
    const theme = useTheme()

    return (
        <Box
            sx={{
                mt: theme.spacing(1),
                borderRadius: 1,
                backgroundColor: theme.palette.background.default,
                p: 1,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
                cursor: 'pointer'
            }}
        >
            <Box
                onClick={handleSelect}
                sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
            }}>
                {avatar && <Avatar sx={{width: 24, height: 24}} alt={avatar.alt} src={avatar.src} />}
                <Box sx={{ml: 1}}>
                    <Typography variant='body1'>{primaryText}</Typography>
                </Box>
            </Box>
            {icon &&
                <IconButton onClick={icon.handleIconClick} size={'small'}>
                    <MuiIcon  name={icon.name} sx={{fontSize: theme.spacing(2)}}/>
                </IconButton>
            }
        </Box>
    )
}


SubtleListItem.propTypes = {
    primaryText: PropTypes.string.isRequired,
    avatar: PropTypes.shape({
        src: PropTypes.string,
        alt: PropTypes.string
    }),
    icon: PropTypes.shape({
        name: PropTypes.string,
        handleIconClick: PropTypes.func
    }),
    handleSelect: PropTypes.func
}

SubtleListItem.defaultParameters = {
    icon: null,
    avatar: null,
    handleSelect: () => {}
}


export default SubtleListItem;
