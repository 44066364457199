import React from 'react';
import Container from "components/layouts/Container";
import NothingToShow from "components/bits/NothingToShow";

const Subscriptions = () => {
    return (
        <Container title={'Subscription Details'}>
            <NothingToShow />
        </Container>

    )
};

export default Subscriptions;